import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useWasteMatrixData, WasteMatrixData } from '../model/useWasteMatrixData';
import { GridApi } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

export const WasteMatrixTable = ({
  width,
  height,
  colWidths,
  rowHeight,
  setGridAPI
}: {
  width: number;
  height: number;
  colWidths: {
    construction: number;
    productGroup: number;
    others: number;
  },
  rowHeight: number;
  setGridAPI?: (api: GridApi<WasteMatrixData>) => void;
}) => {
  const { t } = useTranslation();
  const { construction, productGroup, others } = colWidths;
  const wasteMatrixData = useWasteMatrixData();

  const rowSpan = (param: any) => {
    if (param.data[t('waste-matrix.sector')] === t('waste-matrix.packaging'))
      return 3;
    if (param.data[t('waste-matrix.sector')] === t('waste-matrix.construction'))
      return 2;
    if (param.data[t('waste-matrix.sector')] === t('waste-matrix.automotive'))
      return 2;
    if (param.data[t('waste-matrix.sector')] === t('waste-matrix.house-appliance'))
      return 2;
    if (param.data[t('waste-matrix.sector')] === t('waste-matrix.furniture'))
      return 2;
    return 1;
  };

  const rowSpan2 = (param: any) =>
    param.data[t(t('waste-matrix.categories'))]
      === t('waste-matrix.waste-type') ? 14 : 1

  const [columnDefs] = useState(
    [{
      headerName: '',
      children: [
        {
          field: t('waste-matrix.categories'),
          width: others,
          rowSpan: rowSpan2,
          cellClass: 'left-title'
        },
        {
          headerName: t('waste-matrix.sector'),
          children: [
            {
              field: t('waste-matrix.sector'),
              width: others,
              rowSpan: rowSpan,
              cellClass: 'row-group-header'
            },
            {
              field: t('waste-matrix.product-group'),
              width: productGroup,
              cellClass: 'row-header'
            }
          ]
        }]
    },

    {
      headerName: t('waste-matrix.application'),
      children: [
        {
          headerName: t('waste-matrix.packaging'),
          children: [
            {
              field: t('waste-matrix.packaging-food'),
              width: others,
            },
            {
              field: t('waste-matrix.other-packaging'),
              width: others
            },
            {
              field: t('waste-matrix.industrial'),
              width: others
            }
          ],
        },
        {
          headerName: t('waste-matrix.construction'),
          children: [
            {
              field: t('waste-matrix.const-indoor'),
              width: construction
            },
            {
              field: t('waste-matrix.const-outdoor'),
              width: construction
            }
          ]
        },
        {
          headerName: t('waste-matrix.automotive'),
          children: [
            {
              field: t('waste-matrix.auto-indoor'),
              width: construction
            },
            {
              field: t('waste-matrix.auto-outdoor'),
              width: construction
            }
          ]
        },
        {
          field: t('waste-matrix.electronics'),
          width: others
        },
        {
          field: t('waste-matrix.agriculture'),
          width: others
        },
        {
          headerName: t('waste-matrix.house-appliance'),
          children: [
            {
              field: t('waste-matrix.food'),
              width: others
            },
            {
              field: t('waste-matrix.other-appliance'),
              width: others,
              autoHeaderHeight: true,
            }
          ]
        },
        {
          headerName: t('waste-matrix.furniture'),
          children: [
            {
              field: t('waste-matrix.furniture-indoor'),
              width: others
            },
            {
              field: t('waste-matrix.furniture-outdoor'),
              width: others
            }
          ]
        },
        {
          field: t('waste-matrix.medicine'),
          width: others
        }]
    }]
  );

  return (
    <div id="waste-matrix-table">
      <div className="ag-theme-alpine waste-matrix-table"
        style={{ width: width, height: height }}>
        <AgGridReact
          onGridReady={param => { if (setGridAPI) setGridAPI(param.api) }}
          rowData={wasteMatrixData}
          columnDefs={columnDefs}
          headerHeight={40}
          groupHeaderHeight={50}
          rowHeight={rowHeight}
          suppressRowTransform={true}
        >
        </AgGridReact>
      </div>
    </div>
  )
};
