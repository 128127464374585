import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { PlasticFields } from '../model/usePlasticFields';
import sampleEconData from '../data/sampleEconData.json';
import {
  createHoverTextList,
  createPriceListDisplayAndRepetitionCount,
  getMarkerSizeListByAmountRepetition,
} from '../utils/economicAssessment';
import {
  EcoAssessDataItem,
  EcoAssessPlotDisplayData
} from '../model/economicAssessment';
import {
  MAX_MARKER_SIZE,
} from './EconomicAssessment';
import { useTranslation } from 'react-i18next';
import constants from '../utils/constants';
import { Data, Layout } from 'plotly.js';

export const EconomicAssessmentChart = ({
  sampleData,
  setSampleData,
  plasticFields,
  setOpenNotification
}: {
  sampleData: EcoAssessDataItem[] | undefined;
  setSampleData?: React.Dispatch<
    React.SetStateAction<EcoAssessDataItem[]>
  >;
  plasticFields?: PlasticFields;
  setOpenNotification?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) => {
  const { t } = useTranslation();
  const { NO_INPUT, GREY_BORDER, COLOR_SCALE, OPACITY } = constants;

  const dinSpec = plasticFields?.dinSpec;
  const materialType = plasticFields?.materialType;
  const recurringOffer = plasticFields?.recurring;
  const color = plasticFields?.colour;
  const frequency = plasticFields?.frequency;
    const source = plasticFields?.residueFrom;
  const amount = plasticFields?.materialAmount;

  const [displayData, setDisplayData] = useState<EcoAssessPlotDisplayData>();
  const [priceRepetitionCount, setPriceRepetitionCount]
    = useState<number[]>([]);

  const setPlotSampleData = () => {
    const priceList: number[] | undefined = sampleData?.map(item => item.price);
    let priceListDisplay: number[];
    let repetitionCount: number[];
    let hoverTextList: string[];

    [priceListDisplay, repetitionCount] =
      createPriceListDisplayAndRepetitionCount(priceList as number[]);
    hoverTextList = createHoverTextList(priceListDisplay, repetitionCount, t);

    if (setSampleData)
      setSampleData(sampleEconData);
    setPriceRepetitionCount(repetitionCount);
    setDisplayData({
      price: priceListDisplay,
      hoverText: hoverTextList,
      size: getMarkerSizeListByAmountRepetition(
        repetitionCount,
        MAX_MARKER_SIZE
      ) as number[]
    });
  };

  const filterSampleData = () => {
    let copyData = [...sampleData as EcoAssessDataItem[]];
    if (dinSpec && dinSpec !== NO_INPUT) {
      copyData = copyData.filter(item => item.dinSpec === dinSpec);
    }
    if (materialType && materialType !== t('no-input')) {
      copyData = copyData.filter(item => item.materialType === materialType);
    }
    if (recurringOffer && recurringOffer !== NO_INPUT) {
      copyData = copyData.filter(
        item => item.recurringOffer === recurringOffer
      );
    }
    if (color && color !== NO_INPUT) {
      copyData = copyData.filter(item => item.color === color);
    }
    if (frequency && frequency !== NO_INPUT) {
      copyData = copyData.filter(item => item.frequency === frequency);
    }
    if (source && source !== NO_INPUT) {
      copyData = copyData.filter(
        item => item.recyclateSource === parseInt(source as string)
      );
    }
    if (amount && amount !== NO_INPUT) {
      copyData = copyData.filter(
        item => item.amountInTon === Math.round(parseFloat(amount as string))
      );
    }
    const priceList = copyData.map(item => item.price);
    const [priceListDisplay, repetitionCount] =
      createPriceListDisplayAndRepetitionCount(priceList);
    const hoverTextList =
      createHoverTextList(priceListDisplay, repetitionCount, t);
    setDisplayData({
      price: priceListDisplay,
      hoverText: hoverTextList,
      size: getMarkerSizeListByAmountRepetition(
        repetitionCount,
        MAX_MARKER_SIZE
      ) as number[]
    });
    setPriceRepetitionCount(repetitionCount);
    // If no data points are available to display after filtering,
    // show a notification telling users to limit the number of criteria
    if (setOpenNotification) {
      if (priceListDisplay.length === 0)
        setOpenNotification(true);
      else
        setOpenNotification(false);
    }
  };

  useEffect(setPlotSampleData, []);

  useEffect(() => {
    if (sampleData) {
      filterSampleData();
    }
  }, [dinSpec, materialType, recurringOffer,
    color, frequency, source, amount
  ]);

  const data: Data[] = [
    {
      x: displayData?.price,
      y: displayData?.price.map(() => 1),
      type: 'scatter',
      mode: 'markers',
      marker: {
        size: displayData?.size,
        opacity: OPACITY,
        color: priceRepetitionCount,
        line: {
          color: GREY_BORDER,
          width: 1
        },
        showscale: true,
        reversescale: true,
        colorscale: COLOR_SCALE,
        colorbar: {
          title: t('chart.amount') as string
        }
      },
      hoverinfo: 'text',
      text: displayData?.hoverText
    }
  ];

  const layout: Partial<Layout> = {
    width: 700,
    height: 270,
    title: t('chart.price-distribution') as string,
    showlegend: false,
    margin: {
      t: 30,
      b: 40,
      l: 60,
      r: 0
    },
    xaxis: {
      zeroline: false,
      title: {
        text: t('chart.price-euro') as string
      }
    },
    yaxis: {
      showline: false,
      showticklabels: false
    }
  };

  return (
    <>
      {displayData && displayData.price &&
        <>
          <Plot data={data} layout={layout} />
        </>
      }
    </>
  );
};
