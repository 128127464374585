import { ChangeEvent } from 'react';
import { PlasticFields } from '../model/usePlasticFields';

export const inputFieldChange = (
  event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  fieldName: string,
  setPlasticFields: Function) => {
  setPlasticFields((fields: PlasticFields) => {
    const copy = { ...fields };
    let value: string | number = event.target.value;
    if (["materialAmount", "salesValue"].includes(fieldName))
      value = parseFloat(value);
    copy[fieldName as keyof PlasticFields] = value;
    return copy;
  });
};

export const setPlasticFieldAttribute = (
  fieldName: string,
  value: string | number,
  setPlasticFields: Function
) => {
  setPlasticFields((fields: PlasticFields) => {
    const copy = { ...fields };
    copy[fieldName as keyof PlasticFields] = value;
    return copy;
  });
};
