import { useTranslation } from "react-i18next";
import { OptionItem } from "./model";
import constants from "../utils/constants";

export const useColourOptions = () => {
  const { t } = useTranslation();
  const { NO_INPUT, WHITE, GREY, TRANSPARENT, MIXTURE } = constants;

  const colourOptions: OptionItem[] = [
    { text: t('colour-options.mixture'), value: MIXTURE },
    { text: t('colour-options.transparent'), value: TRANSPARENT },
    { text: t('colour-options.grey'), value: GREY },
    { text: t('colour-options.white'), value: WHITE },
    { text: t('colour-options.no-input'), value: NO_INPUT }
  ];

  return colourOptions;
};

