import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart, CategoryScale, LinearScale,
  BarElement, Title, Tooltip, Legend
} from 'chart.js';
import { METHOD } from '../model/results';
import * as results from "../model/results";
import { switchArrayItemPosition } from '../utils/comparisonChart';
import { indexOfLongestLabel } from '../utils/comparisonChart';
import { useTranslation } from 'react-i18next';
import c from '../utils/constants';

export const impCatLangMapping = {
  "Climate change": "Klimawandel",
  "Water use": "Wassernutzung",
  "Resources, fossils": "Ressourcen, fossile Energie",
  "Resources, elements": "Ressourcen, Elemente"
};

export const ComparisonChart = ({
  items,
  comparisonSubjects
}: {
  items: results.ComparisonItem[];
  comparisonSubjects: string[];
}) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
    }[]
  }>();
  const labels: string[] = [];
  const primData: number[] = [];
  const recData: number[] = [];

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const getBarChartData = () => {
    for (const item of items) {
      let label = item.indicator.name as string;
      // if the current language is german, switch english labels to german
      if (i18n.language === c.DE)
        label = impCatLangMapping[label as keyof typeof impCatLangMapping];
      labels.push(label as string);
      primData.push(item.primaryShare);
      recData.push(item.recyclingShare);
    }

    const longestLabelIndex = indexOfLongestLabel(labels);
    const indexToMoveTo = Math.floor(labels.length / 2);

    //move the longest indicator label to the middle of the array
    //so it won't get cut off by the boundary of the chart element
    switchArrayItemPosition(longestLabelIndex, indexToMoveTo, labels);

    //data items also need to be switched since the order of data items
    //corresponds to the order of indicator labels
    switchArrayItemPosition(longestLabelIndex, indexToMoveTo, primData);
    switchArrayItemPosition(longestLabelIndex, indexToMoveTo, recData);

    const datasets = [
      {
        label: comparisonSubjects[0],
        data: primData,
        backgroundColor: c.CYCLOPS_BLUE
      },
      {
        label: comparisonSubjects[1],
        data: recData,
        backgroundColor: c.DARK_GREEN
      }
    ];
    setData({
      labels: labels,
      datasets: datasets
    });
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        align: "end" as const,
      },
      title: {
        display: true,
        text: t('ecol-assess.impact-result')
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: `${t('ecol-assess.impact-category-method')}: ${METHOD.name}`
        }
      },
      y: {
        max: 100,
        title: {
          display: true,
          text: t('ecol-assess.percentage')
        },
        ticks: {
          callback: function (value: any, index: any, ticks: any) {
            return value + ' %';
          }
        }
      }
    }
  };

  const handleImpCatLangSwitch = () => {
    if (!data || !data.labels)
      return;
    const translatedLabels = data.labels.map(label => {
      let translatedLabel = "";
      if (i18n.language === c.DE)
        translatedLabel =
          impCatLangMapping[label as keyof typeof impCatLangMapping];
      else
        for (const pair of Object.entries(impCatLangMapping))
          if (pair[1] === label)
            translatedLabel = pair[0];
      return translatedLabel;
    });
    setData({ ...data, labels: translatedLabels });
  };

  useEffect(() => {
    if (labels.length === 0)
      getBarChartData();
  }, []);

  useEffect(() => {
    if (!i18n.language)
      return;
    handleImpCatLangSwitch();
  }, [i18n.language]);

  return (
    <>
      {data &&
        <Bar data={data} options={options} />
      }
    </>
  );
};
