import { Dispatch, SetStateAction } from "react";
import * as api from "./api";
import { BuyerMaterial, BUYER_MATERIALS } from "./materials";
import { ImpactDistributionData, ImpactResult, ParameterRedef, Ref } from "./model";

export const METHOD: Ref = {
  "@id": "42baa28f-59f9-4f97-9f0e-63c45aa487a0",
  name: "EF 3.0 Method (adapted)"
};

const SELLER_SYSTEM: Ref = {
  "@id": "c5e94de5-c432-40ef-9840-e6a845346be5",
  name: "Seller"
};

const BUYER_SYSTEM: Ref = {
  "@id": "7c064f77-5dc6-462e-ae4c-8d2eea3cd45a",
  name: "Buyer"
};

const CONVERTER_SYSTEM: Ref = {
  "@id": "1c107199-7512-4f16-98d0-2b0603177383",
  name: "Converter"
};

export interface Setup {
  productSystem: Ref;
  impactMethod: Ref;
  parameterRedefs: ParameterRedef[];
}

export interface Status {
  "@id": string;
  "error"?: string;
  isReady?: boolean;
  isScheduled?: boolean;
  time?: number;
}

export interface Result {
  impacts?: ImpactResult[];
  error?: string;
}

export const prepareContributionImpactChartData = (
  results: ImpactResult[][],
  setAllContributionImpactData: Dispatch<
    SetStateAction<ImpactDistributionData>
  >
) => {
  const data: ImpactDistributionData = {};
  for (let i = 0; i < results[0].length; i++) {
    const impactCategory = results[0][i].impactCategory.name as string;
    data[impactCategory] = [];
    for (let j = 0; j < results.length; j++) {
      data[impactCategory].push(results[j][i].amount);
    }
  };
  setAllContributionImpactData(data);
};

export const getContributionImpactResult = async (
  resultId: string,
  techFlowIdByRecycleProcName: { [key: string]: string }
) => {
  const promises: Promise<ImpactResult[]>[] =
    Object.entries(techFlowIdByRecycleProcName).map(pair => {
      const techFlowId = pair[1];
      const endpoint = `/result/${resultId}/total-impacts-of/${techFlowId}`;
      return api.get(endpoint);
    });
  const results: ImpactResult[][] =
    await Promise.all<ImpactResult[]>(promises);
  return results;
};

export async function calculate(
  setup: Setup,
  recyclingCalculation = false,
  setAllContributionImpactData?: Dispatch<
    SetStateAction<ImpactDistributionData>
  >,
  techFlowIdByRecycleProcName?: { [key: string]: string }
): Promise<Result> {
  // post the calculation and wait until result is ready
  let status: Status = await api.postGetJson('/result/calculate', setup);
  if (status.error) {
    return { error: status.error };
  }
  const resultId = status['@id'];
  while (!status.isReady) {
    await sleep(100);
    status = await api.get(`/result/${resultId}/state`);
  }

  // fetch impact results
  const impacts: ImpactResult[] = await api.get(
    `/result/${resultId}/total-impacts`);

  // get contribution impact results for contribution impact pie chart
  // and prepare data for the chart
  if (recyclingCalculation && setAllContributionImpactData
    && techFlowIdByRecycleProcName) {
    const results =
      await getContributionImpactResult(resultId, techFlowIdByRecycleProcName);
    prepareContributionImpactChartData(results, setAllContributionImpactData);
  }
  // dispose the result
  api.post(`/result/${resultId}/dispose`);

  return { impacts }
}

export async function calculatePrimaryImpacts(
  material: string, mass: number): Promise<Result> {
  let parameter = "";
  const buyerMaterial = BUYER_MATERIALS.find(
    (buyerMaterial: BuyerMaterial) => {
      for (const flow of buyerMaterial.flows) {
        if (flow.label === material) {
          parameter = flow.parameter;
          return true;
        }
      }
    }
  );
  const setup = {
    productSystem: SELLER_SYSTEM,
    impactMethod: METHOD,
    parameterRedefs: [
      {
        "name": "recyclate_amount",
        "value": mass,
      },
      {
        "name": parameter,
        "value": 1,
        context: {
          "@id": (buyerMaterial as BuyerMaterial)["@id"],
          "@type": (buyerMaterial as BuyerMaterial)["@type"],
          name: (buyerMaterial as BuyerMaterial).name
        }
      },
    ]
  };
  return calculate(setup);
}

export async function calculateRecyclingImpacts(
  parameters: ParameterRedef[],
  mass: number,
  setAllContributionImpactData: Dispatch<
    SetStateAction<ImpactDistributionData>
  >,
  techFlowIdByRecycleProcName: { [key: string]: string }
): Promise<Result> {
  const setup = {
    productSystem: BUYER_SYSTEM,
    impactMethod: METHOD,
    parameterRedefs: [...parameters,
    {
      "name": "recyclate_amount",
      "value": mass,
    },
    ]
  };
  return calculate(
    setup,
    true,
    setAllContributionImpactData,
    techFlowIdByRecycleProcName
  );
}

export async function calculateProductImpacts(
  parameters: ParameterRedef[],
): Promise<Result> {
  const setup = {
    productSystem: CONVERTER_SYSTEM,
    impactMethod: METHOD,
    parameterRedefs: parameters
  };
  return calculate(setup);
}

export interface ComparisonItem {
  indicator: Ref;
  primaryResult: number;
  primaryShare: number;
  recyclingResult: number;
  recyclingShare: number;
}

export function filterResultByImpactCategory(result: ComparisonItem[]) {
  if (!result)
    return [];
  const filteredCategories = [
    "Acidification",
    "Ozone depletion",
    "Particulate matter"
  ];
  return result.filter(r =>
    !filteredCategories.includes(r.indicator.name as string)
  );
};

export function compare(
  primary: Result,
  recycling: Result
): ComparisonItem[] {

  const primIdx: { [key: string]: ImpactResult } = {};
  if (primary.impacts) {
    for (const impact of primary.impacts) {
      primIdx[impact.impactCategory["@id"]] = impact;
    }
  }

  const items: ComparisonItem[] = [];
  if (recycling.impacts) {
    for (const recImp of recycling.impacts) {
      const primImp = primIdx[recImp.impactCategory["@id"]];
      if (!primImp) {
        items.push({
          indicator: recImp.impactCategory,
          recyclingResult: recImp.amount,
          recyclingShare: recImp.amount > 0 ? 100 : 0,
          primaryResult: 0,
          primaryShare: 0
        });
      } else {
        const max = Math.max(recImp.amount, primImp.amount);
        items.push({
          indicator: recImp.impactCategory,
          recyclingResult: recImp.amount,
          recyclingShare: max === 0 ? 0 : 100 * recImp.amount / max,
          primaryResult: primImp.amount,
          primaryShare: max === 0 ? 0 : 100 * primImp.amount / max,
        });
      }
    }
  }
  return items;
}

async function sleep(ms: number) {
  return new Promise(f => setTimeout(f, ms));
}
