import React, { MouseEvent } from 'react';
import { OriginalUse } from '../model/useOriginalUseOptions';
import { useTranslation } from 'react-i18next';

export const OriginalUseDropdown = ({
  transKey,
  options,
  onDropdownSelect
}: {
  transKey: string,
  options: OriginalUse[],
  onDropdownSelect: (event: MouseEvent<HTMLAnchorElement>) => void
}) => {
  const { t } = useTranslation();

  return (
    <div className="dropdown">
      <button
        className="btn btn-light btn-lg dropdown-toggle bg-white"
        type="button"
        id="dropdownMenuButton"
        data-mdb-toggle="dropdown"
        aria-expanded="false">
        {t(transKey)}
      </button>
      <ul className="dropdown-menu"
        aria-labelledby="dropdownMenuButton">
        {
          options.map((option, index) =>
            <li key={index}>
              <a className="dropdown-item"
                onClick={option.useCases.length === 0 ?
                  onDropdownSelect :
                  undefined
                }
              >
                {option.label}
                {option.useCases.length > 0 &&
                  <span className="right-arrow">&gt;</span>
                }

              </a>
              {option.useCases ?
                <ul className="dropdown-menu dropdown-submenu">
                  {
                    option.useCases.map((useCase, index) =>
                      <li key={index}>
                        <a className="dropdown-item"
                          onClick={onDropdownSelect}>
                          {useCase}
                        </a>
                      </li>
                    )
                  }
                </ul>
                : <></>
              }
            </li>
          )
        }
      </ul>
    </div>
  );
};
