import { useTranslation } from "react-i18next";
import { OptionItem } from "./model";
import constants from "../utils/constants";

export const useFrequencyOptions = () => {
  const { t } = useTranslation();
  const { ONCE, WEEKLY, BI_WEEKLY, MONTHLY,
    QUARTERLY, YEARLY, NO_INPUT } = constants;

  const frequencyOptions: OptionItem[] = [
    { text: t('frequency-options.one-off'), value: ONCE },
    { text: t('frequency-options.weekly'), value: WEEKLY },
    { text: t('frequency-options.bi-weekly'), value: BI_WEEKLY },
    { text: t('frequency-options.monthly'), value: MONTHLY },
    { text: t('frequency-options.quarterly'), value: QUARTERLY },
    { text: t('frequency-options.yearly'), value: YEARLY },
    { text: t('frequency-options.no-input'), value: NO_INPUT }
  ];

  return frequencyOptions;
};

