import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { OpenLCAProcess, ProcessCategory } from "../model/model";
import { filteredOutProcesses } from "../utils/buy";
import c from "../utils/constants";

export const NestedDropdown = ({ data, value, onDropdownSelect }: {
  data: ProcessCategory;
  value: string;
  onDropdownSelect: MouseEventHandler<HTMLAnchorElement>
}) => {
  const { t } = useTranslation();
  const copyData: ProcessCategory = { ...data };

  const moveToTopAndRenameProcess = (processes: OpenLCAProcess[]) => {
    let processIndex = -1;

    processes.forEach((process, index) => {
      if (process.name.startsWith("Non-machine-specific")) {
        process.name = `Generic data (${process.name})`;
        processIndex = index;
      }
    });

    if (processIndex !== -1 && processIndex !== 0) {
      const temp = processes[processIndex];
      processes[processIndex] = processes[0];
      processes[0] = temp;
    }

    return processes;
  };

  return (
    <div className="dropdown">
      <button className="btn btn-light btn-lg dropdown-toggle bg-white"
        type="button"
        data-mdb-toggle="dropdown" aria-expanded="false">
        {value}
      </button>
      <ul className="dropdown-menu">
        {
          moveToTopAndRenameProcess(copyData.subProcesses.filter((p) =>
            !filteredOutProcesses.includes(p.name)
          )).map((process) =>
            <li key={process["@id"]}>
              <a className="dropdown-item" onClick={onDropdownSelect}>
                {process.name}
              </a>
            </li>
          )
        }
        {
          copyData.subCategories.map((category) =>
            <li key={category.category}>
              <a className="dropdown-item">
                {category.title}
                <span className="right-arrow">&gt;</span>
              </a>
              <ul className="dropdown-menu dropdown-submenu">
                {
                  category.subProcesses.filter((p) =>
                    !filteredOutProcesses.includes(p.name)
                  ).map((process) =>
                    <li key={process["@id"]}>
                      <a className="dropdown-item" onClick={onDropdownSelect}>
                        {process.name}
                      </a>
                    </li>
                  )
                }
                {
                  category.subCategories.map((category) =>
                    <li key={category.category}>
                      <a className="dropdown-item">
                        {category.title}
                        <span className="right-arrow">&gt;</span>
                      </a>
                      <ul className="dropdown-menu dropdown-submenu">
                        {
                          category.subProcesses.filter((p) =>
                            !filteredOutProcesses.includes(p.name)
                          ).map((process) =>
                            <li key={process["@id"]}>
                              <a className="dropdown-item"
                                onClick={onDropdownSelect}>
                                {process.name}
                              </a>
                            </li>
                          )
                        }
                      </ul>
                    </li>
                  )
                }
              </ul>
            </li>
          )
        }
        <li key={c.NO_INPUT}>
          <a className="dropdown-item" onClick={onDropdownSelect}>
            {t('no-input')}
          </a>
        </li>
      </ul>
    </div>
  );
};
