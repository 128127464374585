import React, { useEffect } from "react";
import { useState } from "react";
import { ComparisonChart } from "./ComparisonChart";
import ClipLoader from "react-spinners/ClipLoader";
import * as results from "../model/results";
import {
  ParameterDef,
  TransportOption,
  transportRedefsOf
} from "../model/useTransportOptions";
import { useReprocessingOptions } from "../model/useReprocessingOptions";
import { ParameterRedef, Stakeholder }
  from "../model/model";
import { ComparisonItem, filterResultByImpactCategory, Result } from "../model/results";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import {
  ConverterProductContext,
  ConverterProductItem
} from "../model/context";
import { useTranslation } from "react-i18next";
import { SaveAlert } from "./SaveAlert";
import c from "../utils/constants";
import dashboardDataManager from '../service/DashboardDataManager';
import stakeholderData from '../service/StakeholderData';

export const useConverterContext = () => {
  return useOutletContext<ConverterProductContext>();
};

export const ConverterEcologicalAssessment = () => {
  const { t } = useTranslation();
  const reprocessingOptions = useReprocessingOptions();
  const [products, setProducts] = useState<ConverterProductItem[]>([
    {
      material: t('no-input'),
      mass: 1,
      transport: {
        label: t('no-input'),
        parameter: "",
        context: { "@id": "" }
      },
      transportTransKey: 'no-input',
      distance: 0,
      reprocessing: []
    },
    {
      material: t('no-input'),
      mass: 1,
      transport: {
        label: t('no-input'),
        parameter: "",
        context: { "@id": "" }
      },
      transportTransKey: 'no-input',
      distance: 0,
      reprocessing: []
    }
  ]);
  const [result, setResult] =
    useState<ComparisonItem[] | undefined>(undefined);
  const [comparisonSubjects, setComparisonSubjects] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [stepperPosition, setStepperPosition] = useState(1);
  const [stepperSwitchCount, setStepperSwitchCount] = useState(0);
  const [saveAlertVisible, setSaveAlertVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    clearStepperActive();
    navigateToPage();
    setActiveStepper();
    setStepperSwitchCount(count => count + 1);
  }, [stepperPosition]);

  const clearStepperActive = () => {
    const stepperItems = document.getElementsByTagName('li');
    for (let i = 0; i < stepperItems.length; i++) {
      stepperItems[i].classList.remove('is-active');
    }
  };

  function setActiveStepper() {
    const activeStepper = document.getElementById(`converter-ecol-step-${stepperPosition}`);
    activeStepper?.classList.add('is-active');
  }

  function navigateToPage() {
    let pageUrl = "/converter-ecological-assessment/converter-product";
    navigate(pageUrl, {
      state: {
        productIndex: stepperPosition - 1
      }
    });
  };

  const prepareProductParameters = (product: ConverterProductItem) => {
    const params: ParameterRedef[] = [];
    if (product?.transport?.label !== t('no-input') && product.distance > 0)
      for (const parameter of transportRedefsOf(
        product.transport as TransportOption, product.distance
      ))
        params.push(parameter);

    params.push({
      "name": "recyclate_amount",
      "value": product.mass,
    });

    if (product.reprocessing) {
      for (const item of product.reprocessing) {
        const reprocessing: ParameterDef | undefined =
          reprocessingOptions.find(
            obj => obj.parameter === item.value
          );
        if (reprocessing)
          params.push({
            name: reprocessing.parameter,
            value: 1,
            context: reprocessing.context
          });
      }
    }
    return params;
  };

  const updateComparisonSubjects = () => {
    const newComparisonSubjects: string[] = [];
    const subjectPrefixes = [
      `${t('converter-ecol-assess.product')} 1`,
      `${t('converter-ecol-assess.product')} 2`
    ];
    subjectPrefixes.forEach((prefix, index) => {
      const addition = products[index].material === t('no-input') ?
        '' : `(${products[index].material})`;
      const fullSubject = `${prefix} ${addition}`;
      newComparisonSubjects.push(fullSubject);
    });
    setComparisonSubjects(newComparisonSubjects);
  };

  const calculateComparison = async () => {
    const [product1, product2] = products;
    const product1Params = prepareProductParameters(product1);
    const product2Params = prepareProductParameters(product2);

    setResult(undefined);
    setLoading(true);

    const impactPair: Result[] = await Promise.all<Promise<Result>>([
      results.calculateProductImpacts(product1Params),
      results.calculateProductImpacts(product2Params)
    ]);

    setLoading(false);
    let result = results.compare(impactPair[0], impactPair[1]);
    result = filterResultByImpactCategory(result);
    setResult(result);
  };

  const onStepperChange = (toNextStepper: boolean) => {
    const change = toNextStepper ? 1 : -1;
    setStepperPosition(position => position + change);
  };

  const saveInDashboard = () => {
    const dashboardData = dashboardDataManager
      .getDataByStakeholder(stakeholderData.stakeholder as Stakeholder);
    const ecolDataManager = dashboardData.ecolAssessChartData;

    ecolDataManager.setChartData(result);
    ecolDataManager.setChartComparisonSubjects(comparisonSubjects);

    setSaveAlertVisible(true);
  };

  const isNextStepReady = () => {
    let isReady = false;
    let { reprocessing, material } = products[0];
    if (stepperPosition === 1 && material !== t('no-input') &&
      reprocessing && reprocessing.length > 0)
      isReady = true;
    return isReady;
  }

  const isReadyToCalculate = () => {
    let isReady = false;
    let { reprocessing, material } = products[0];
    let reprocessing2 = products[1].reprocessing;
    let material2 = products[1].material;
    if (material !== t('no-input') && material2 !== t('no-input') &&
      reprocessing && reprocessing.length > 0 &&
      reprocessing2 && reprocessing2.length > 0)
      isReady = true;
    return isReady;
  };

  return (
    <div className="body-padding">
      <div id="converter-ecological-assessment">
        <h1 className="title is-1 has-text-primary">
          {t('ecol-assess.title')}
        </h1>
        <div className="container"
          id="converter-ecological-assessment-content">
          <div className="columns is-mobile">
            <div className='column is-2'>
              <aside className="menu">
                <p className="menu-label is-capitalized is-size-6">
                  {t('ecol-assess.input')}
                </p>
                <ul className="steps is-vertical">
                  {products.map((product, index) =>
                    <li id={`converter-ecol-step-${index + 1}`} key={index}
                      className={'steps-segment ' + ((index === 0) ? 'is-active' : '')}>
                      <span className="steps-marker">{index + 1}</span>
                      <div className="steps-content">
                        <p className="is-size-6">{t('converter-ecol-assess.product')} {index + 1}</p>
                        {product.material !== c.NO_INPUT && <p>{product.material}</p>}
                      </div>
                    </li>
                  )}
                </ul>
                <button className='button is-info'
                  style={{ marginRight: "1.5rem" }}
                  onClick={() => onStepperChange(false)}
                  disabled={stepperPosition === 1}>
                  {t('ecol-assess.previous')}
                </button>
                <button className='button is-info'
                  onClick={() => onStepperChange(true)}
                  disabled={stepperPosition === 2 || !isNextStepReady()}>
                  {t('ecol-assess.next')}
                </button>
                <p className="menu-label is-capitalized is-size-6">
                  {t('converter-ecol-assess.env-impact-assess')}
                </p>
                <div className="block">
                  <button className='button is-info is-responsive'
                    onClick={() => {
                      updateComparisonSubjects();
                      calculateComparison();
                    }}
                    disabled={!isReadyToCalculate()}>
                    {t('act-button.calculate')}
                  </button>
                </div>
                <div className="block">
                  <button className='button is-info back-button'
                    onClick={() => { navigate(-1 - stepperSwitchCount) }}>
                    {t('nav-button.back')}
                  </button>
                </div>
              </aside>
            </div>
            <div className='column is-3'>
              <Outlet context={{ products, setProducts }} />
            </div>
            <div className='column'>
              {loading &&
                <div className="spinner">
                  <ClipLoader color={c.CYCLOPS_BLUE}
                    loading={loading}
                    cssOverride={{
                      display: "block",
                      margin: "0 auto"
                    }} size={100} />
                </div>
              }
              {result &&
                <>
                  <div className="container" id="chart-container">
                    <ComparisonChart items={result}
                      comparisonSubjects={comparisonSubjects}
                    />
                  </div>
                  <div className="notification chart-explain">
                    {t('converter-ecol-assess.explanation')}
                  </div>
                </>
              }
              {result &&
                <>
                  <button
                    className='button is-info save-dashboard block'
                    onClick={saveInDashboard}
                  >
                    {t('act-button.save-dashboard')}
                  </button>
                  <SaveAlert
                    visible={saveAlertVisible}
                    setVisible={setSaveAlertVisible}
                  />
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
