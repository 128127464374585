import React from "react";
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const SaveAlert = ({
  visible,
  setVisible
}: {
  visible: boolean,
  setVisible: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation();

  return (
    <>
      {visible &&
        <>
          <span className='tag is-success is-large saved-alert'>
            {t('notification.saved')}
          </span>
          <article className="message is-success block">
            <div className="message-header">
              <p>Info</p>
              <button className="delete" aria-label="delete"
                onClick={() => setVisible(false)}
              >
              </button>
            </div>
            <div className="message-body">
              {t('notification.save-dashboard-complete')}&nbsp;
              <Link to='/dashboard' >Dashboard</Link>
            </div>
          </article>
        </>
      }
    </>
  );
};
