import { useTranslation } from "react-i18next";
import constants from "../utils/constants";

export interface PlasticFields {
  materialType: number | string;
  mixComponent1: number | string;
  mixComponent2: number | string;
  materialComposition: number | string;
  materialAmount: number | string;
  salesValue: number | string;
  originalUse: number | string;
  colour: number | string;
  residueFrom: number | string;
  dinSpec: number | string;
  recurring: number | string;
  frequency: number | string;
}

export const usePlasticFields = () => {
  const { t } = useTranslation();
  const { NO_INPUT } = constants;

  const initialState: PlasticFields = {
    materialType: t('no-input') as string,
    mixComponent1: t('no-input') as string,
    mixComponent2: t('no-input') as string,
    materialComposition: 0,
    materialAmount: 0,
    salesValue: 0,
    originalUse: t('no-input') as string,
    colour: NO_INPUT,
    residueFrom: NO_INPUT,
    dinSpec: NO_INPUT,
    recurring: NO_INPUT,
    frequency: NO_INPUT
  };

  return initialState;
};
