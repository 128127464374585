import { ImpactDistributionData } from "../model/model";
import { ComparisonItem } from "../model/results";

class EcolAssessChartData {
  chartData: ComparisonItem[] | undefined;
  chartComparisonSubjects: string[] | undefined;
  impactDistributionData: ImpactDistributionData | undefined;

  setChartData(chartData: ComparisonItem[] | undefined) {
    this.chartData = chartData;
  }

  setChartComparisonSubjects(chartComparisonSubjects: string[] | undefined) {
    this.chartComparisonSubjects = chartComparisonSubjects;
  }

  setImpactDistributionData(
    impactDistributionData: ImpactDistributionData | undefined
  ) {
    this.impactDistributionData = impactDistributionData;
  }
}

export default EcolAssessChartData;
