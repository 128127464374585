import React from 'react';
import greenDeltaLogo from '../images/greendelta-logo.png';
import skzLogo from '../images/skz-logo.png';
import wuppertalLogo from '../images/wuppertal-logo.png';
import cirplusLogo from '../images/cirplus-logo.png';
import bmbfLogo from '../images/bmbf-logo.png';

export const Footer = () => {
  return (
    <footer className="footer">
      <div className='columns is-mobile is-multiline is-vcentered'>
        <div className='column is-10' id='logos'>
          <div className='logo-text'>
            Developed by © GreenDelta GmbH 2023
          </div>
          <div className='columns'>
            <div className='column col-logo'>
              <a href="https://www.greendelta.com/" target='_blank'>
                <img id="green-delta-logo" src={greenDeltaLogo} />
              </a>
            </div>
            <div className='column col-logo'>
              <a href="https://www.cirplus.com/" target='_blank'>
                <img id="cirplus-logo" src={cirplusLogo} />
              </a>
            </div>
            <div className='column col-logo'>
              <a href="https://wupperinst.org/" target='_blank'>
                <img id="wuppertal-logo" src={wuppertalLogo} />
              </a>
            </div>
            <div className='column col-logo'>
              <a href="https://www.skz.de/" target='_blank'>
                <img id="skz-logo" src={skzLogo} />
              </a>
            </div>
          </div>
        </div>
        <div className='column is-2' id='sponsor'>
          <div id='sponsor-text'>Sponsored by the</div>
          <div>
            <a href="https://www.bmbf.de/" target='_blank'>
              <img id="bmbf-logo" src={bmbfLogo} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
