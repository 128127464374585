interface Flow {
  label: string;
  parameter: string;
}

export interface BuyerMaterial {
  "@type"?: string;
  "@id": string;
  name?: string;
  flows: Flow[];
}

export const BUYER_MATERIALS: BuyerMaterial[] = [
  {
    "@type": "Process",
    "@id": "97e8ec1e-6056-46aa-8b80-8cc2f7f009a6",
    name: "Polycarbonate PC",
    flows: [
      {
        label: "Polycarbonate",
        parameter: "PC"
      }
    ]
  },
  {
    "@type": "Process",
    "@id": "9018127e-fd60-403f-86d3-3a844f920828",
    name: "Polymethy methacrylate PMMA",
    flows: [
      {
        label: "Polymethyl methacrylate, beads",
        parameter: "PMMA_beads",
      }
    ]
  },
  {
    "@type": "Process",
    "@id": "947523ec-2972-421d-acb6-6fade4847482",
    name: "Polyethylene PE",
    flows: [
      {
        label: "Polyethylene, high density, granulate",
        parameter: "HDPE"
      },
      {
        label: "Polyethylene, linear low density, granulate",
        parameter: "LDPE_linear"
      },
      {
        label: "Polyethylene terephthalate, granulate, amorphous",
        parameter: "PET_amorphous"
      },
      {
        label: "Polyethylene terephthalate, granulate, bottle grade",
        parameter: "PET_bottle_grade"
      },
      {
        label: "Polyethylene, low density, granulate",
        parameter: "LDPE"
      }
    ]
  },
  {
    "@type": "Process",
    "@id": "d9383fe1-a8a1-4c15-b66f-5e08ff1ee508",
    name: "Polystyrene PS",
    flows: [
      {
        label: "Polystyrene, extruded, HFC-152a blown",
        parameter: "PS_extruded_HFC152a"
      },
      {
        label: "Polystyrene, general purpose",
        parameter: "PS_general_purpose"
      },
      {
        label: "Polystyrene, extruded, CO2 blown",
        parameter: "PS_extruded_CO2_blown"
      },
      {
        label: "Polystyrene, high impact",
        parameter: "PS_high_impact"
      },
      {
        label: "Polystyrene, extruded, HFC-134a blown",
        parameter: "PS_extruded_HFC134a"
      },
      {
        label: "Polystyrene, expandable",
        parameter: "PS_expandable"
      }
    ]
  },
  {
    "@type": "Process",
    "@id": "40feb992-1a68-4797-bf66-0141bbe03079",
    name: "Polyvinylchloride PVC",
    flows: [
      {
        label: "Polyvinylchloride, emulsion polymerised",
        parameter: "PVC_emulsion"
      },
      {
        label: "Polyvinylchloride, bulk polymerised",
        parameter: "PVC_bulk"
      },
      {
        label: "Polyvinylidenchloride, granulate",
        parameter: "PVC_granulate"
      },
      {
        label: "Polyvinylchloride, suspension polymerised",
        parameter: "PVC_suspension"
      }
    ]
  },
  {
    "@type": "Process",
    "@id": "f23edbd6-ecb4-4f23-92ce-1f0444a23ef4",
    name: "Polyamide PA",
    flows: [
      {
        label: "Nylon 6",
        parameter: "nylon_6"
      },
      {
        label: "Nylon 6-6",
        parameter: "nylon_66"
      }
    ]
  },
  {
    "@type": "Process",
    "@id": "8b1a3e2b-0eb2-4999-b6b8-366d9ce810f2",
    name: "Polyurethane PU ",
    flows: [
      {
        label: "Polyurethane, flexible foam, MDI-based",
        parameter: "PU_ff_MDI"
      },
      {
        label: "Polyurethane, flexible foam, TDI-based, flame retardant",
        parameter: "PU_ff_TDI_flame_retardant"
      },
      {
        label: "Polyurethane, flexible foam, TDI-based, high density",
        parameter: "PU_ff_TDI_high_density"
      },
      {
        label: "Polyurethane, flexible foam, TDI-based, low density",
        parameter: "PU_ff_TDI_low_density"
      },
      {
        label: "Polyurethane, rigid foam",
        parameter: "PU_rigid_foam"
      }
    ]
  },
  {
    "@type": "Process",
    "@id": "d4e13fc6-0ca3-4e8f-9b81-d4131aa8a247",
    name: "Polyproylene PP",
    flows: [
      {
        label: "Polypropylene, granulate",
        parameter: "PP_granulate"
      }
    ]
  },
  {
    "@type": "Process",
    "@id": "d32c31ce-4673-4d92-9516-fcfaf7a5bb2f",
    name: "Polyester",
    flows: [
      {
        label: "Polyester resin, unsaturated",
        parameter: "polyester_resin"
      }
    ]
  }
];
