import React, { ChangeEventHandler } from "react";
import { OptionItem } from "../model/model";
import { Select } from "./Select";

export const PlasticInput = ({
  label = "",
  selectValue,
  onSelectChange,
  options,
}: {
  label?: string;
  selectValue: string | number;
  onSelectChange: ChangeEventHandler<HTMLSelectElement>;
  options: OptionItem[];
}) => {
  return (
    <>
      <div className='field'>
        <label className="label">{label}</label>
        <div className="plastic-input">
          <Select value={selectValue}
            onSelectChange={onSelectChange}
            options={options} />
        </div>
      </div>
    </>
  );
};
