import React from 'react';

export const Error404 = () => {
  return (
    <div className='container'>
      <div className='box my-6 mx-6'>
        <h3 className='title is-2'>
          The requested page was not found, please enter a valid path.
        </h3>
      </div>
    </div>
  );
};
