import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Home = () => {
  const { t } = useTranslation();
  return (
    <div className='body-wrapper body-padding' id='home-container'>
      <h3 className='title is-1 has-text-primary'>
        {t('home.title')}
      </h3>
      <div className='flex-break' />
      <h4 className='title is-3'>
        {t('home.subtitle')}
      </h4>
      <div className='flex-break' />
      <div className='columns'>
        <div className='column is-full'>
          <fieldset>
            <legend id="about-title">{t('home.about.legend')}</legend>
            <div className='content' id='about-text'>
              <p>{t('home.about.text')}</p>
              <ul>
                <li>{t('home.about.li1')}</li>
                <li>{t('home.about.li2')}</li>
                <li>{t('home.about.li3')}</li>
                <li>{t('home.about.li4')}</li>
                <li>{t('home.about.li5')}</li>
              </ul>
            </div>
          </fieldset>
        </div>
      </div>
      <div className='columns'>
        <div className='column col-same-height is-three-quarters'>
          <fieldset className='max-height'>
            <legend id="background-title">{t('home.background.legend')}</legend>
            <div className='content max-height' id='background-text'>
              <p>{t('home.background.li1')}</p>
              <p>{t('home.background.li2')}</p>
            </div>
          </fieldset>
        </div>
        <div className='column col-same-height'>
          <fieldset className='max-height'>
            <legend id='disclaimer-title'>{t('home.disclaimer.legend')}</legend>
            <p className='content max-height' id='disclaimer-text'>
              {t('home.disclaimer.text')}
            </p>
          </fieldset>
        </div>
      </div>
      <Link to='/choose-stakeholder'>
        <button id='start-button' className='button is-medium
          is-responsive is-primary nav-button'
        >
          {t('home.access')}
        </button>
      </Link>
    </div>
  );
};
