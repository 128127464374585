import {
  CalculationSetup,
  ParameterRedef,
  Ref,
  RefType,
  RestClient
} from "olca-ipc";

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
let restClient: RestClient;

const initRestClient = () => {
  if (restClient)
    return;
  restClient = RestClient.on(ENDPOINT as string);
};

export const getOlcaEntitiesByType = async (type: RefType) => {
  if (!restClient)
    initRestClient();
  const entities: Ref[] = await restClient.getDescriptors(type);
  return entities;
};

export const getOlcaEntityByTypeAndId = async (type: RefType, id: string) => {
  if (!restClient)
    initRestClient();
  const entity: Ref | null = await restClient.getDescriptor(type, id);
  return entity;
};

export const calculateImpacts = async (
  target: Ref,
  impactMethod: Ref,
  parameters: ParameterRedef[]
) => {
  if (!restClient)
    initRestClient();
  const setup = CalculationSetup.of({
    target,
    impactMethod,
    parameters
  });
  const result = await restClient.calculate(setup);
  const state = await result.untilReady();
  if (state.error) {
    throw new Error(`calculation failed: ${state.error}`);
  }
  const impacts = await result.getTotalImpacts();
  return impacts;
}

export async function get(path: string): Promise<any> {
  const resp = await fetch(url(path));
  const json = await resp.json();
  return json;
}

export async function postGetJson(path: string, data: any): Promise<any> {
  const resp = await fetch(url(path), {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return resp.json();
}

export async function post(path: string, data?: any) {
  await fetch(url(path), {
    method: 'POST',
    headers: data ? { 'Content-Type': 'application/json' } : undefined,
    body: data ? JSON.stringify(data) : undefined,
  });
}

function url(path: string): string {
  const p = !path.startsWith('/')
    ? '/' + path
    : path;
  return `${ENDPOINT}${p}`
}
