import React, { useEffect, useState } from 'react';
import { setPlasticFieldAttribute }
  from '../utils/inputFieldChange';
import { useOriginalUseOptions } from '../model/useOriginalUseOptions';
import { usePlasticFields, PlasticFields } from '../model/usePlasticFields';
import { OriginalUseDropdown } from './OriginalUseDropdown';
import { WasteMatrixTable } from './WasteMatrixTable';
import { GridApi, RowNode } from 'ag-grid-community';
import { WasteMatrixData } from '../model/useWasteMatrixData';
import domToImage from 'dom-to-image';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import transEn from '../translations/en/translation.json';
import transDe from '../translations/de/translation.json';
import { i18n } from 'i18next';
import { SaveAlert } from './SaveAlert';
import c from '../utils/constants';
import dashboardDataManager from '../service/DashboardDataManager';
import stakeholderData from '../service/StakeholderData';
import { Stakeholder } from '../model/model';

export const getTranslationKeyByValue = (
  value: string,
  i18n: i18n,
  transParentKey: keyof typeof transEn
) => {
  let translation: any;
  if (i18n.language === c.EN)
    translation = transDe[transParentKey];
  else
    translation = transEn[transParentKey];
  for (const pair of Object.entries(translation))
    if (pair[1] === value)
      return `${transParentKey}.${pair[0]}`;
  return value;
};

export const WasteMatrix = () => {
  const { t, i18n } = useTranslation();
  const originalUseOptions = useOriginalUseOptions();
  const initialState = usePlasticFields();
  const [plasticFields, setPlasticFields] =
    useState<PlasticFields>(initialState);
  const [gridAPI, setGridAPI] = useState<GridApi<WasteMatrixData>>();
  const [saveAlertVisible, setSaveAlertVisible] = useState(false);
  const [originUseTransKey, setOriginUseTransKey] =
    useState('waste-matrix.no-input');
  const originalUse = plasticFields.originalUse as string;

  const initGridAPI = (api: GridApi<WasteMatrixData>) => {
    setGridAPI(api);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!originalUse || !gridAPI)
      return;
    gridAPI.forEachNode((rowNode: RowNode<WasteMatrixData>, index: number) => {
      if (rowNode.data?.[t('waste-matrix.product-group')] === originalUse)
        rowNode.setSelected(true, true);
    });
  }, [originalUse]);

  useEffect(() => {
    if (!i18n.language)
      return;
    setOriginUseTransKey(getTranslationKeyByValue(
      originalUse, i18n, 'waste-matrix'
    ));
  }, [i18n.language]);

  const saveInDashboard = () => {
    const node = document.getElementsByClassName('waste-matrix-table')[0];
    domToImage.toPng(node as HTMLElement)
      .then(function (image) {
        const dashboardData = dashboardDataManager
          .getDataByStakeholder(stakeholderData.stakeholder as Stakeholder);
        const wasteMatrixDataManager = dashboardData.wasteMatrixData;
        wasteMatrixDataManager.setWasteMatrixImage(image);
      })
      .catch(function (error) {
        console.error('Cannot generate image from dom: ', error);
      })
      .finally(function () {
        setSaveAlertVisible(true);
      });
  };

  return (
    <div className="body-padding">
      <div className='container' id='waste-matrix-container'>
        <h3 className="title is-1 has-text-primary">{t('waste-matrix.title')}</h3>
        <div className='waste-matrix-content'>
          <label className="label">
            {t('waste-matrix.original-use')}
          </label>
          <OriginalUseDropdown
            transKey={originUseTransKey}
            options={originalUseOptions}
            onDropdownSelect={(e) => {
              const value = e.currentTarget.innerText;
              setPlasticFieldAttribute("originalUse", value, setPlasticFields);
              setOriginUseTransKey(getTranslationKeyByValue(
                value, i18n, 'waste-matrix'
              ));
            }} />
          <WasteMatrixTable
            width={1225}
            height={1025}
            colWidths={{
              construction: 65,
              productGroup: 238,
              others: 60
            }}
            rowHeight={50}
            setGridAPI={initGridAPI}
          />
          <div className='waste-matrix-legend block'>
            <p><strong></strong>{t('waste-matrix.legend')}:</p>
            <p><strong>✓</strong>: {t('waste-matrix.suitable')}</p>
            <p><strong>O</strong>: {t('waste-matrix.cond-suitable')}</p>
            <p><strong>Ø</strong>: {t('waste-matrix.unsuitable')}</p>
          </div>
          <div className='block'>
            <button className='button is-responsive is-info block'
              onClick={saveInDashboard}>
              {t('act-button.save-dashboard')}
            </button>
            <SaveAlert
              visible={saveAlertVisible}
              setVisible={setSaveAlertVisible}
            />
          </div>
          <div className='block'>
            <button
              className='button is-info back-button'
              onClick={() => { navigate(-1) }}
            >
              {t('nav-button.back')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
