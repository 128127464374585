import React, { MouseEvent, useEffect } from 'react';
import { InputNumber } from './InputNumber';
import { useBuyerMaterial } from './EcologicalAssessment';
import { MaterialDropdown } from './MaterialDropdown';
import * as materials from "../model/materials";
import { useTranslation } from 'react-i18next';
import transEn from '../translations/en/translation.json';
import transDe from '../translations/de/translation.json';
import { i18n } from 'i18next';
import c from '../utils/constants';

export const handleFieldValueLangSwitch = (
  fieldValue: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  i18n: i18n
) => {
  if (i18n.language === c.EN && fieldValue === transDe['no-input'])
    setValue(transEn['no-input']);
  if (i18n.language === c.DE && fieldValue === transEn['no-input'])
    setValue(transDe['no-input']);
};

export const BuyerGeneralData = () => {
  const { t, i18n } = useTranslation();
  const buyerMaterial = useBuyerMaterial();
  const [mass, setMass] = buyerMaterial.mass;
  const [material, setMaterial] = buyerMaterial.material;

  useEffect(() => {
    if (!i18n.language)
      return;
    handleFieldValueLangSwitch(material, setMaterial, i18n);
  }, [i18n.language]);

  const onDropdownSelect = (event: MouseEvent<HTMLAnchorElement>) => {
    const selectedLabel = event.currentTarget.innerText;
    setMaterial(selectedLabel);
  };

  return (
    <div id="buyer-general-data">
      <div className='field'>
        <label className="label">
          {t('ecol-assess.general-data.material-type')}&nbsp;
          <span className="icon" data-tooltip={t('mandatory-field')}>
            <i className="fa-solid fa-circle-info fa-lg" style={{color: c.CYCLOPS_BLUE}}></i>
          </span>
        </label>
        <MaterialDropdown value={material}
          options={materials.BUYER_MATERIALS}
          onDropdownSelect={onDropdownSelect} />
      </div>
      <InputNumber
        label={t('ecol-assess.general-data.material-weight')}
        value={mass}
        onChange={num => setMass(num)}
        unit={t('ton')} />
    </div>
  );
};
