import React, { MouseEvent, useEffect, useState } from 'react';
import { InputNumber } from './InputNumber';
import { TransportOption, useTransportOptions } from "../model/useTransportOptions";
import { useBuyerMaterial } from './EcologicalAssessment';
import { useTranslation } from 'react-i18next';
import { getTranslationKeyByValue } from './WasteMatrix';
import c from '../utils/constants';

export const BuyerTransportation = () => {
  const { t, i18n } = useTranslation();
  const transportOptions = useTransportOptions();
  const buyerMaterial = useBuyerMaterial();
  const [distance, setDistance] = buyerMaterial.distance;
  const [transport, setTransport] = buyerMaterial.transport;
  const [transportTransKey, setTransportTransKey] =
    useState('transport-options.no-input');

  const onDropdownSelect = (event: MouseEvent<HTMLAnchorElement>) => {
    const selectedLabel = event.currentTarget.innerText;
    let selectedTransport;
    if (selectedLabel === t('transport-options.no-input')) {
      selectedTransport = {
        label: t('transport-options.no-input'),
        parameter: "",
        context: { "@id": "" }
      };
    } else {
      selectedTransport = transportOptions.find(
        option => option.label === selectedLabel
      ) as TransportOption;
    }
    setTransport(selectedTransport);
    setTransportTransKey(getTranslationKeyByValue(
      selectedLabel, i18n, 'transport-options'
    ));
  };

  useEffect(() => {
    if (!i18n.language)
      return;
    setTransportTransKey(getTranslationKeyByValue(
      transport?.label as string, i18n, 'transport-options'
    ));
  }, [i18n.language]);

  return (
    <div id='buyer-transportation'>
      <div className='field'>
        <label className="label">
          {t('ecol-assess.transport.transport-type')}
        </label>
        <div className="dropdown">
          <button className="btn btn-light btn-lg dropdown-toggle bg-white"
            type="button"
            id="dropdownMenuButton"
            data-mdb-toggle="dropdown"
            aria-expanded="false">
            {t(transportTransKey)}
          </button>
          <ul className="dropdown-menu"
            aria-labelledby="dropdownMenuButton">
            {
              transportOptions.filter(
                (option) => option.context.name !== 'Road'
              ).map((option, index) =>
                <li key={index}>
                  <a className="dropdown-item"
                    onClick={onDropdownSelect}>{option.label}</a>
                </li>
              )
            }
            <li>
              <a className="dropdown-item">
                {t('transport-options.road')} <span className="right-arrow">&gt;</span>
              </a>
              <ul className="dropdown-menu dropdown-submenu">
                {
                  transportOptions.filter(
                    (option) => option.context.name === 'Road'
                  ).map((option, index) =>
                    <li key={index}>
                      <a className="dropdown-item"
                        onClick={onDropdownSelect}>{option.label}</a>
                    </li>
                  )
                }
              </ul>
            </li>
            <li key={c.NO_INPUT}>
              <a className="dropdown-item" onClick={onDropdownSelect}>
                {t('transport-options.no-input')}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <InputNumber
        label={t('ecol-assess.transport.transport-distance')}
        value={distance}
        onChange={setDistance}
        unit={"km"} />
    </div>
  );
};
