import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { EcologicalAssessment } from './EcologicalAssessment';
import { Layout } from './Layout';
import { Home } from './Home';
import { Error404 } from './Error404';
import { BuyerGeneralData } from './BuyerGeneralData';
import { BuyerRecyclingSteps } from './BuyerRecyclingSteps';
import { BuyerTransportation } from './BuyerTransportation';
import { Recycler } from './Recycler';
import { WasteOwnerTools } from './WasteOwnerTools';
import { EconomicAssessment } from './EconomicAssessment';
import { BuyPlasticWaste } from './BuyPlasticWaste';
import { WasteMatrix } from './WasteMatrix';
import { SellRecyclates } from './SellRecyclates';
import { ConverterTools } from './ConverterTools';
import { Dashboard } from './Dashboard';
import {
  ConverterEcologicalAssessment
} from './ConverterEcologicalAssessment';
import { ConverterProduct } from './ConverterProduct';
import { DesignForRecycling } from './DesignForRecycling';
import { ChooseStakeholder } from './ChooseStakeholder';

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='choose-stakeholder' element={<ChooseStakeholder />} />
          <Route path='waste-owner-tools' element={<WasteOwnerTools />} />
          <Route path='economic-assessment' element={<EconomicAssessment />} />
          <Route path='waste-matrix' element={<WasteMatrix />} />
          <Route path='recycler' element={<Recycler />} />
          <Route path='buy-plastic-waste' element={<BuyPlasticWaste />} />
          <Route path='sell-recyclates' element={<SellRecyclates />} />
          <Route path='design-recycling' element={<DesignForRecycling />} />
          <Route
            path='converter-ecological-assessment'
            element={<ConverterEcologicalAssessment />}
          >
            <Route index element={<ConverterProduct />} />
            <Route path='converter-product' element={<ConverterProduct />} />
          </Route>
          <Route path='converter-tools' element={<ConverterTools />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route
            path='ecological-assessment'
            element={<EcologicalAssessment />}
          >
            <Route index element={<BuyerGeneralData />} />
            <Route path='general-data' element={<BuyerGeneralData />} />
            <Route path='recycling-steps' element={<BuyerRecyclingSteps />} />
            <Route path='transportation' element={<BuyerTransportation />} />
          </Route>
          <Route path='*' element={<Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

