import { useTranslation } from "react-i18next";

export interface OriginalUse {
  label: string,
  useCases: string[]
}

export const useOriginalUseOptions = () => {
  const { t } = useTranslation();

  const originalUseOptions: OriginalUse[] = [
    {
      label: t('waste-matrix.packaging'),
      useCases: [
        t('waste-matrix.packaging-food'),
        t('waste-matrix.other-packaging'),
        t('waste-matrix.industrial')
      ]
    },
    {
      label: t('waste-matrix.construction'),
      useCases: [
        t('waste-matrix.const-indoor'),
        t('waste-matrix.const-outdoor')
      ]
    },
    {
      label: t('waste-matrix.automotive'),
      useCases: [
        t('waste-matrix.auto-indoor'),
        t('waste-matrix.auto-outdoor')
      ]
    },
    {
      label: t('waste-matrix.electronics'),
      useCases: []
    },
    {
      label: t('waste-matrix.agriculture'),
      useCases: []
    },
    {
      label: t('waste-matrix.house-appliance'),
      useCases: [
        t('waste-matrix.food'),
        t('waste-matrix.other-appliance')
      ]
    },
    {
      label: t('waste-matrix.furniture'),
      useCases: [
        t('waste-matrix.furniture-indoor'),
        t('waste-matrix.furniture-outdoor')
      ]
    },
    {
      label: t('waste-matrix.medicine'),
      useCases: []
    },
    {
      label: t('waste-matrix.other'),
      useCases: []
    },
    {
      label: t('waste-matrix.no-input'),
      useCases: []
    }
  ];

  return originalUseOptions;
}
