import { useTranslation } from "react-i18next";
import { OptionItem } from "./model";
import constants from "../utils/constants";

export const useRecurringOptions = () => {
  const { t } = useTranslation();
  const { ONCE, RECURRING, NO_INPUT } = constants;

  const recurringOptions: OptionItem[] = [
    { text: t('recurring-options.one-off'), value: ONCE },
    { text: t('recurring-options.recurring'), value: RECURRING },
    { text: t('recurring-options.no-input'), value: NO_INPUT }
  ];

  return recurringOptions;
};

