import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { BuyerMaterial } from '../model/materials';
import c from '../utils/constants';

export const MaterialDropdown = ({
  value,
  options,
  onDropdownSelect,
  hasMaterialMix=false
}: {
  value: string,
  options: BuyerMaterial[],
  onDropdownSelect: (event: MouseEvent<HTMLAnchorElement>) => void,
  hasMaterialMix?: boolean
}) => {
  const { t } = useTranslation();

  return (
    <div className="dropdown">
      <button className="btn btn-light btn-lg dropdown-toggle bg-white"
        type="button"
        id="dropdownMenuButton"
        data-mdb-toggle="dropdown"
        aria-expanded="false">
        {value}
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {
          options.map((option, index) =>
            <li key={index}>
              <a className="dropdown-item">{option.name}
                <span className="right-arrow">&gt;</span>
              </a>
              {option.flows ?
                <ul className="dropdown-menu dropdown-submenu">
                  {
                    option.flows.map((flow, index) =>
                      <li key={index}>
                        <a className="dropdown-item"
                          onClick={onDropdownSelect}>
                            {flow.label}
                        </a>
                      </li>
                    )
                  }
                </ul>
                : <></>
              }
            </li>
          )
        }
        {hasMaterialMix ?
          <li key="Material mix">
            <a className="dropdown-item" onClick={onDropdownSelect}>
              {t('econ-assess.material-mix')}
            </a>
          </li> : <></>
        }
        <li key={c.NO_INPUT}>
          <a className="dropdown-item" onClick={onDropdownSelect}>
            {t('no-input')}
          </a>
        </li>
      </ul>
    </div>
  );
};
