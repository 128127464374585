import React, { ChangeEvent, useEffect, useState } from "react";
import Select, { MultiValue } from 'react-select';
import { Select as SingleSelect } from "./Select";
import { useDesign4RecyclingOptions } from "../model/useDesign4RecyclingOptions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import constants from '../utils/constants';

export const DesignForRecycling = () => {
  const { t, i18n } = useTranslation();
  const { NO_INPUT, PACKAGING, CYCLOPS_BLUE } = constants;
  const {
    recommendations,
    recommendationsByWasteType,
    wasteTypes
  } = useDesign4RecyclingOptions();
  const [recommendation, setRecommendation] = useState<MultiValue<{
    value: string;
    label: string;
  }>>();
  const [wasteType, setWasteType] = useState(NO_INPUT);
  const [
    recommendationTexts,
    setRecommendationTexts
  ] = useState<{ [key: string]: string[] }>();
  const navigate = useNavigate();

  const filterRecommendationTexts = () => {
    const filteredTexts: { [key: string]: string[] } = {};
    let recommendations: { [key: string]: string[] } = {};

    recommendations = recommendationsByWasteType(wasteType);

    if (!recommendation)
      return;

    for (const item of recommendation) {
      filteredTexts[item.value] = recommendations[item.value];
    }

    setRecommendationTexts(filteredTexts);
  };

  const recommendationChange = (recommendation: MultiValue<{
    value: string;
    label: string;
  }>) => {
    setRecommendation(recommendation);
  };

  const onWasteTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setWasteType(event.target.value);
  };

  useEffect(() => {
    if (recommendation)
      filterRecommendationTexts();
  }, [recommendation]);

  useEffect(() => {
    if (i18n.language)
      setRecommendation(recommendation?.map(r => ({
        label: t(r.value),
        value: r.value
      })));
  }, [i18n.language]);

  return (
    <div className="body-padding">
      <div className="container" id="design-recycling-container">
        <h1 className="title is-1 has-text-primary">
          {t('design-recycling.title')}
        </h1>
        <div className="container"
          id="design-recycling-container-content">
          <div className="columns is-mobile is-variable is-5">
            <div className='column is-3'>
              <div className="field">
                <label className="label">
                  {t('design-recycling.product-type')}
                </label>
                <SingleSelect value={wasteType}
                  onSelectChange={onWasteTypeChange}
                  options={wasteTypes} />
              </div>
              <div className='field'>
                <label className="label">
                  {t('design-recycling.recommendation')}
                </label>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? CYCLOPS_BLUE : CYCLOPS_BLUE
                    }),
                  }}
                  value={recommendation}
                  onChange={recommendationChange}
                  options={recommendations}
                  isMulti={true}
                />
              </div>
              <button className='button is-info back-button'
                onClick={() => { navigate(-1) }}
              >
                {t('nav-button.back')}
              </button>
            </div>
            <div className='column is-9'>
              {recommendationTexts && Object.entries(recommendationTexts).map(pair =>
                <article className="message is-info">
                  <div className="message-header">
                    <p>{t(pair[0])}</p>
                  </div>
                  <div className="message-body">
                    <ol>
                      {pair[1].map(item => (
                        <li>{t(item)}</li>
                      ))}
                    </ol>
                  </div>
                </article>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
