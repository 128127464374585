import React, { ChangeEventHandler } from "react";
import { OptionItem } from "../model/model";

export const Select = ({value, options, onSelectChange}: {
  value: string | number;
  options: OptionItem[];
  onSelectChange: ChangeEventHandler<HTMLSelectElement>;
}) => {
  return (
    <div className="select is-primary">
      <select value={value}
        onChange={onSelectChange}>
        {
          options.map((option, index) =>
            <option key={option.text + index} value={option.value}>
              {option.text}
            </option>)
        }
      </select>
    </div>
  );
};
