import React, { MouseEvent, useEffect } from 'react';
import { InputNumber } from './InputNumber';
import { MaterialDropdown } from './MaterialDropdown';
import * as materials from "../model/materials";
import {
  TransportOption,
  useTransportOptions
} from '../model/useTransportOptions';
import { useReprocessingOptions } from '../model/useReprocessingOptions';
import Select, { MultiValue } from 'react-select';
import { useLocation } from 'react-router-dom';
import { useConverterContext } from './ConverterEcologicalAssessment';
import { useTranslation } from 'react-i18next';
import c from '../utils/constants';
import transEn from '../translations/en/translation.json';
import transDe from '../translations/de/translation.json';
import { getTranslationKeyByValue } from './WasteMatrix';

export const ConverterProduct = () => {
  const { t, i18n } = useTranslation();
  const transportOptions = useTransportOptions();
  const { products, setProducts } = useConverterContext();
  const location = useLocation();
  const productIndex = location.state ? location.state.productIndex : 0;
  const product = products[productIndex];
  const reprocessingOptions = useReprocessingOptions().map(item => ({
    label: item.label,
    value: item.parameter,
    translationKey: item.translationKey
  }));
  const materialDropdownSelect = (event: MouseEvent<HTMLAnchorElement>) => {
    const selectedLabel = event.currentTarget.innerText;
    const copyProducts = [...products];
    copyProducts[productIndex].material = selectedLabel;
    setProducts(copyProducts);
  };

  const transportDropdownSelect = (event: MouseEvent<HTMLAnchorElement>) => {
    const selectedLabel = event.currentTarget.innerText;
    let selectedTransport;
    if (selectedLabel === t('no-input')) {
      selectedTransport = {
        label: t('no-input') as string,
        parameter: "",
        context: { "@id": "" }
      };
    } else {
      selectedTransport = transportOptions.find(
        option => option.label === selectedLabel
      ) as TransportOption;
    }
    const copyProducts = [...products];
    copyProducts[productIndex].transport = selectedTransport;
    copyProducts[productIndex].transportTransKey =
      getTranslationKeyByValue(selectedLabel, i18n, 'transport-options');
    setProducts(copyProducts);
  };

  const reprocessingChange = (reprocessing: MultiValue<{
    value: string;
    label: string;
    translationKey: string;
  }>) => {
    const copyProducts = [...products];
    copyProducts[productIndex].reprocessing = reprocessing;
    setProducts(copyProducts);
  };

  const setMass = (mass: number) => {
    const copyProducts = [...products];
    copyProducts[productIndex].mass = mass;
    setProducts(copyProducts);
  };

  const setDistance = (distance: number) => {
    const copyProducts = [...products];
    copyProducts[productIndex].distance = distance;
    setProducts(copyProducts);
  };

  const handleMaterialLangSwitch = () => {
    const copyProducts = [...products];
    for (const product of copyProducts) {
      if (i18n.language === c.EN && product.material === transDe['no-input'])
        product.material = transEn['no-input'];
      if (i18n.language === c.DE && product.material === transEn['no-input'])
        product.material = transDe['no-input'];
    }
    setProducts(copyProducts);
  };

  useEffect(() => {
    if (!i18n.language)
      return;
    handleMaterialLangSwitch();
    const copyProducts = [...products];
    for (const product of copyProducts) {
      product.transportTransKey = getTranslationKeyByValue(
        product.transport?.label as string, i18n, 'transport-options'
      );
      if (product.reprocessing)
        for (const reprocessing of product.reprocessing)
          reprocessing.label = t(reprocessing.translationKey);
    }
    setProducts(copyProducts);
  }, [i18n.language]);

  return (
    <div id="converter-product">
      <div className='field'>
        <label className="label">
          {t('ecol-assess.general-data.material-type')}&nbsp;
          <span className="icon" data-tooltip={t('mandatory-field')}>
            <i className="fa-solid fa-circle-info fa-lg" style={{color: c.CYCLOPS_BLUE}}></i>
          </span>
        </label>
        <MaterialDropdown value={product.material}
          options={materials.BUYER_MATERIALS}
          onDropdownSelect={materialDropdownSelect} />
      </div>

      <InputNumber
        label={t('ecol-assess.general-data.material-weight')}
        value={product.mass}
        onChange={num => setMass(num)}
        unit={t('ton')} />

      <div className='field'>
        <label className="label">
          {t('converter-ecol-assess.reprocess-tech')}&nbsp;
          <span className="icon" data-tooltip={t('mandatory-field')}>
            <i className="fa-solid fa-circle-info fa-lg" style={{color: c.CYCLOPS_BLUE}}></i>
          </span>
        </label>
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? c.CYCLOPS_BLUE : c.CYCLOPS_BLUE,
              width: '91.7%'
            }),
          }}
          value={product.reprocessing}
          onChange={reprocessingChange}
          options={reprocessingOptions}
          isMulti={true}
        />
      </div>

      <div className='field'>
        <label className="label">
          {t('ecol-assess.transport.transport-type')}
        </label>
        <div className="dropdown">
          <button className="btn btn-light btn-lg dropdown-toggle bg-white"
            type="button"
            id="dropdownMenuButton"
            data-mdb-toggle="dropdown"
            aria-expanded="false">
            {t(product.transportTransKey)}
          </button>
          <ul className="dropdown-menu"
            aria-labelledby="dropdownMenuButton">
            {
              transportOptions.filter(
                (option) => option.context.name !== 'Road'
              ).map((option, index) =>
                <li key={index}>
                  <a className="dropdown-item"
                    onClick={transportDropdownSelect}>{option.label}</a>
                </li>
              )
            }
            <li>
              <a className="dropdown-item">
                {t('transport-options.road')} <span className="right-arrow">&gt;</span>
              </a>
              <ul className="dropdown-menu dropdown-submenu">
                {
                  transportOptions.filter(
                    (option) => option.context.name === 'Road'
                  ).map((option, index) =>
                    <li key={index}>
                      <a className="dropdown-item"
                        onClick={transportDropdownSelect}>{option.label}</a>
                    </li>
                  )
                }
              </ul>
            </li>
            <li key={c.NO_INPUT}>
              <a className="dropdown-item" onClick={transportDropdownSelect}>
                {t('no-input')}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <InputNumber
        label={t('ecol-assess.transport.transport-distance')}
        value={product.distance}
        onChange={setDistance}
        unit={"km"} />
    </div>
  );
};
