import { useTranslation } from "react-i18next";
import { ParameterRedef, Ref } from "./model";
import constants from "../utils/constants";

export interface ParameterDef {
  label: string;
  parameter: string;
  context?: Ref;
}

export type FlagMap = { [param: string]: boolean };

export interface TransportOption {
  label: string;
  parameter: string;
  context: Ref;
}

export const useTransportOptions = () => {
  const { t } = useTranslation();

  const transportOptions: TransportOption[] = [
    {
      label: t('transport-options.air'),
      parameter: "transport_air",
      context: {
        "@id": "ff7b613d-d5a7-4901-98f2-5919aa553b26",
        "@type": "Process",
        "name": "Air",
      },
    },
    {
      label: t('transport-options.railway'),
      parameter: "transport_railway",
      context: {
        "@id": "2c0e84c2-b917-4376-bd9d-e0f7bd57fce5",
        "@type": "Process",
        "name": "Railway",
      },
    },
    {
      label: t('transport-options.sea'),
      parameter: "transport_sea",
      context: {
        "@id": "8ca03cb1-0d7d-4f8b-88f3-7fbf3869c41f",
        "@type": "Process",
        "name": "Sea",
      },
    },
    {
      label: t('transport-options.small-lorry'),
      parameter: "transport_road_3t",
      context: {
        "@id": "a24a3e4f-b8df-43e9-82ac-ac25c64afee9",
        "@type": "Process",
        "name": "Road",
      },
    },
    {
      label: t('transport-options.medium-lorry'),
      parameter: "transport_road_17t",
      context: {
        "@id": "a24a3e4f-b8df-43e9-82ac-ac25c64afee9",
        "@type": "Process",
        "name": "Road",
      },
    },
    {
      label: t('transport-options.big-lorry'),
      parameter: "transport_road_27t",
      context: {
        "@id": "a24a3e4f-b8df-43e9-82ac-ac25c64afee9",
        "@type": "Process",
        "name": "Road",
      },
    }
  ];

  return transportOptions;
}
export function parameterRedefOf(
  def: ParameterDef,
  value: number
): ParameterRedef {
  return {
    name: def.parameter,
    context: def.context,
    value,
  };
}

export function parameterRedefsOf(
  flags: FlagMap,
  defs: ParameterDef[]
): ParameterRedef[] {
  const redefs = [];
  for (const def of defs) {
    if (flags[def.parameter]) {
      redefs.push(parameterRedefOf(def, 1.0));
    }
  }
  return redefs;
}

export function transportRedefsOf(
  transport: TransportOption,
  distance: number
): ParameterRedef[] {
  if (transport.label === constants.NO_INPUT) {
    return [];
  }
  return [
    {
      name: transport.parameter,
      value: 1.0,
      context: transport.context,
    },
    {
      name: "distance",
      value: distance,
      context: {
        "@type": "Process",
        "@id": "c5920e67-98ac-40b6-af41-258c93973ed5",
        "name": "Transport"
      }
    }
  ]
}
