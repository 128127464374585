import { useTranslation } from "react-i18next";

export interface WasteMatrixData {
  [key: string]: string;
}

export const useWasteMatrixData = () => {
  const { t } = useTranslation();

  const wasteMatrixData: WasteMatrixData[] = [
    {
      [t('waste-matrix.categories')]: t('waste-matrix.waste-type'),
      [t('waste-matrix.sector')]: t('waste-matrix.packaging'),
      [t('waste-matrix.product-group')]: t('waste-matrix.packaging-food'),
      [t('waste-matrix.packaging-food')]: 'O',
      [t('waste-matrix.other-packaging')]: 'O',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: 'O',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'O',
      [t('waste-matrix.other-appliance')]: '✓',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: '✓',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: '',
      [t('waste-matrix.product-group')]: t('waste-matrix.other-packaging'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'O',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: 'O',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: '✓',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: '✓',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: '',
      [t('waste-matrix.product-group')]: t('waste-matrix.industrial'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: '✓',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: 'O',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: 'O',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: 'O',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: t('waste-matrix.construction'),
      [t('waste-matrix.product-group')]: t('waste-matrix.const-indoor'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: '✓',
      [t('waste-matrix.const-indoor')]: '✓',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: '✓',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: 'O',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: 'O',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: '',
      [t('waste-matrix.product-group')]: t('waste-matrix.const-outdoor'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: 'O',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: 'O',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: '✓',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: t('waste-matrix.automotive'),
      [t('waste-matrix.product-group')]: t('waste-matrix.auto-indoor'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: '✓',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: '✓',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: '✓',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: '✓',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: '',
      [t('waste-matrix.product-group')]: t('waste-matrix.auto-outdoor'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'Ø',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: 'O',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: 'O',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: 'O',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: '',
      [t('waste-matrix.product-group')]: t('waste-matrix.electronics'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: '✓',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: '✓',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: '✓',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: '',
      [t('waste-matrix.product-group')]: t('waste-matrix.agriculture'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: 'O',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: 'O',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: 'O',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: 'O',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: t('waste-matrix.house-appliance'),
      [t('waste-matrix.product-group')]: t('waste-matrix.food'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: 'O',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'O',
      [t('waste-matrix.other-appliance')]: 'O',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: 'O',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: '',
      [t('waste-matrix.product-group')]: t('waste-matrix.other-appliance'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: 'O',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: 'O',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: 'O',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: t('waste-matrix.furniture'),
      [t('waste-matrix.product-group')]: t('waste-matrix.furniture-indoor'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: '✓',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: '✓',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: '✓',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: '',
      [t('waste-matrix.product-group')]: t('waste-matrix.furniture-outdoor'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'Ø',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: '✓',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: 'O',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: 'O',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: 'O',
      [t('waste-matrix.medicine')]: 'Ø'
    },
    {
      [t('waste-matrix.categories')]: '',
      [t('waste-matrix.sector')]: '',
      [t('waste-matrix.product-group')]: t('waste-matrix.medicine'),
      [t('waste-matrix.packaging-food')]: 'Ø',
      [t('waste-matrix.other-packaging')]: 'O',
      [t('waste-matrix.industrial')]: 'O',
      [t('waste-matrix.const-indoor')]: 'O',
      [t('waste-matrix.const-outdoor')]: 'O',
      [t('waste-matrix.auto-indoor')]: 'O',
      [t('waste-matrix.auto-outdoor')]: 'O',
      [t('waste-matrix.electronics')]: 'O',
      [t('waste-matrix.agriculture')]: '✓',
      [t('waste-matrix.food')]: 'Ø',
      [t('waste-matrix.other-appliance')]: 'O',
      [t('waste-matrix.furniture-indoor')]: 'O',
      [t('waste-matrix.furniture-outdoor')]: 'O',
      [t('waste-matrix.medicine')]: 'Ø'
    }
  ];

  return wasteMatrixData;
};
