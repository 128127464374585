import React, { useEffect } from "react";
import { useState } from "react";
import { ComparisonChart, impCatLangMapping } from "./ComparisonChart";
import ClipLoader from "react-spinners/ClipLoader";
import * as results from "../model/results";
import { TransportOption } from "../model/useTransportOptions";
import { transportRedefsOf } from "../model/useTransportOptions";
import { ImpactDistributionData, OpenLCAProcess, ParameterRedef, ProcessCategory, Stakeholder } from "../model/model";
import { ComparisonItem, filterResultByImpactCategory, Result } from "../model/results";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { BuyerMaterialContext } from "../model/context";
import { ImpactDistributionChart } from "./ImpactDistributionChart";
import { useTranslation } from "react-i18next";
import { SaveAlert } from "./SaveAlert";
import c from "../utils/constants";
import { useTechFlowIdByRecycleProcName }
  from "../model/useTechFlowIdByRecycleProcName";
import dashboardDataManager from '../service/DashboardDataManager';
import stakeholderData from '../service/StakeholderData';

export const useBuyerMaterial = () => {
  return useOutletContext<BuyerMaterialContext>();
};

export const EcologicalAssessment = () => {
  const { t, i18n } = useTranslation();
  const [material, setMaterial] = useState(t('no-input') as string);
  const [mass, setMass] = useState(1);
  const [stepperPosition, setStepperPosition] = useState(1);
  const [transport, setTransport] = useState<TransportOption>({
    label: t('no-input'),
    parameter: "",
    context: { "@id": "" }
  });
  const [distance, setDistance] = useState(0);
  const [result, setResult] =
    useState<ComparisonItem[] | undefined>(undefined);
  const comparisonSubjects = [
    t('ecol-assess.primary-plastic'),
    t('ecol-assess.recycled-plastic')
  ];
  const [allContributionImpactData, setAllContributionImpactData] =
    useState<ImpactDistributionData>({});
  const [loading, setLoading] = useState(false);
  const [saveAlertVisible, setSaveAlertVisible] = useState(false);
  const [stepperSwitchCount, setStepperSwitchCount] = useState(0);
  const [recyclingProcesses, setRecyclingProcesses] =
    useState<OpenLCAProcess[]>([]);
  const [recyclingParameters, setRecyclingParameters] =
    useState<ParameterRedef[]>([]);
  const [processParameterPairs, setProcessParameterPairs] =
    useState<{ [key: string]: ParameterRedef }>({});
  const [processCategories, setProcessCategories] =
    useState<ProcessCategory>({
      title: "",
      category: "",
      subProcesses: [],
      subCategories: []
    });
  const [washStep, setWashStep] = useState(t('no-input') as string);
  const [agglomerateStep, setAgglomerateStep] =
    useState(t('no-input') as string);
  const [dryStep, setDryStep] = useState(t('no-input') as string);
  const [openDissolveStep, setOpenDissolveStep] =
    useState(t('no-input') as string);
  const [pelletizerStep, setPelletizerStep] =
    useState(t('no-input') as string);
  const [shredStep, setShredStep] = useState(t('no-input') as string);
  const [sortStep, setSortStep] = useState(t('no-input') as string);

  const allStepStates = {
    [c.WASH]: {
      state: washStep,
      setState: setWashStep,
    },
    [c.AGGLOMERATE_COMPRESS]: {
      state: agglomerateStep,
      setState: setAgglomerateStep,
    },
    [c.DRY]: {
      state: dryStep,
      setState: setDryStep,
    },
    [c.OPEN_DISSOLVE]: {
      state: openDissolveStep,
      setState: setOpenDissolveStep,
    },
    [c.PELLETIZERS]: {
      state: pelletizerStep,
      setState: setPelletizerStep,
    },
    [c.SHRED]: {
      state: shredStep,
      setState: setShredStep,
    },
    [c.SORT_SEPARATE]: {
      state: sortStep,
      setState: setSortStep,
    }
  };

  const contextData: BuyerMaterialContext = {
    material: [material, setMaterial],
    mass: [mass, setMass],
    transport: [transport, setTransport],
    distance: [distance, setDistance],
    recyclingProcesses: [recyclingProcesses, setRecyclingProcesses],
    recyclingParameters: [recyclingParameters, setRecyclingParameters],
    processParameterPairs: [processParameterPairs, setProcessParameterPairs],
    processCategories: [processCategories, setProcessCategories],
    allStepStates: allStepStates
  };

  const techFlowIdByRecycleProcName = useTechFlowIdByRecycleProcName();
  const navigate = useNavigate();

  useEffect(() => {
    clearStepperActive();
    navigateToPage();
    setActiveStepper();
    setStepperSwitchCount(count => count + 1);
  }, [stepperPosition]);

  const calculateComparison = async () => {
    setResult(undefined);
    setLoading(true);
    const recyclingParams: ParameterRedef[] = [];
    for (const [, value] of Object.entries(allStepStates)) {
      if (value.state !== t('no-input')) {
        const matchingParameter = processParameterPairs[value.state];
        if (matchingParameter) {
          matchingParameter.value = 1.0;
          recyclingParams.push(matchingParameter);
        }
      }
    }
    if (transport.label !== t('no-input') && distance > 0)
      for (const parameter of transportRedefsOf(transport, distance))
        recyclingParams.push(parameter);
    const impactPair: Result[] = await Promise.all<Promise<Result>>([
      results.calculatePrimaryImpacts(material, mass),
      results.calculateRecyclingImpacts(
        recyclingParams,
        mass,
        setAllContributionImpactData,
        techFlowIdByRecycleProcName
      )
    ]);
    setLoading(false);
    let result = results.compare(impactPair[0], impactPair[1]);
    result = filterResultByImpactCategory(result);
    setResult(result);
  };

  const onStepperChange = (toNextStepper: boolean) => {
    const change = toNextStepper ? 1 : -1;
    setStepperPosition(position => position + change);
  };

  function setActiveStepper() {
    const activeStepper = document.getElementById(`ecol-step-${stepperPosition}`);
    activeStepper?.classList.add('is-active');
  }

  function navigateToPage() {
    let pageUrl = "";
    if (stepperPosition === 1)
      pageUrl = "/ecological-assessment/general-data";
    else if (stepperPosition === 2)
      pageUrl = "/ecological-assessment/recycling-steps";
    else if (stepperPosition === 3)
      pageUrl = "/ecological-assessment/transportation";
    navigate(pageUrl);
  };

  const clearStepperActive = () => {
    const stepperItems = document.getElementsByTagName('li');
    for (let i = 0; i < stepperItems.length; i++) {
      stepperItems[i].classList.remove('is-active');
    }
  };

  const isReadyToCalculate = () => {
    let isReady: boolean = false;
    let countStepStatesWithValues = 0;

    for (const entry of Object.entries(allStepStates)) {
      const stepState = entry[1].state;
      if (stepState !== t('no-input'))
        countStepStatesWithValues++;
    }

    if (material !== t('no-input') && countStepStatesWithValues >= 2 &&
      stepperPosition === 3)
      isReady = true;

    return isReady;
  };

  const saveEcologicalChartData = () => {
    const dashboardData = dashboardDataManager
      .getDataByStakeholder(stakeholderData.stakeholder as Stakeholder);
    const ecolDataManager = dashboardData.ecolAssessChartData;

    ecolDataManager.setChartData(result);
    ecolDataManager.setChartComparisonSubjects(comparisonSubjects);
    ecolDataManager.setImpactDistributionData(allContributionImpactData);

    setSaveAlertVisible(true);
  };

  return (
    <div className="body-padding">
      <div className="container" id="ecol-assess-container">
        <h1 className="title is-1 has-text-primary">
          {t('ecol-assess.title')}
        </h1>
        <div
          id="ecol-assess-container-content"
        >
          <div className="columns is-mobile">
            <div className='column is-3'>

              <aside className="menu">
                <p className="menu-label is-capitalized is-size-6">
                  {t('ecol-assess.input')}
                </p>
                <ul className="steps is-vertical">
                  <li id="ecol-step-1"
                    className="steps-segment is-active">
                    <span className="steps-marker">1</span>
                    <div className="steps-content">
                      <p className="is-size-6">{t('ecol-assess.general-data.menu')}</p>
                      <p>{t('ecol-assess.general-data.hint')}</p>
                    </div>
                  </li>
                  <li id="ecol-step-2"
                    className="steps-segment">
                    <span className="steps-marker">2</span>
                    <div className="steps-content">
                      <p className="is-size-6">{t('ecol-assess.recycling-step.menu')}</p>
                      <p>{t('ecol-assess.recycling-step.hint')}</p>
                    </div>
                  </li>
                  <li id="ecol-step-3"
                    className="steps-segment">
                    <span className="steps-marker">3</span>
                    <div className="steps-content">
                      <p className="is-size-6">{t('ecol-assess.transport.menu')}</p>
                      <p>{t('ecol-assess.transport.hint')}</p>
                    </div>
                  </li>
                </ul>
                <button className='button is-info'
                  style={{ marginRight: "1.5rem" }}
                  onClick={() => onStepperChange(false)}
                  disabled={stepperPosition === 1}>
                  {t('ecol-assess.previous')}
                </button>
                <button className='button is-info'
                  onClick={() => onStepperChange(true)}
                  disabled={stepperPosition === 3}>
                  {t('ecol-assess.next')}
                </button>
                <p className="menu-label is-capitalized is-size-6">
                  {t('ecol-assess.impact-assess')}
                </p>
                <div className='block'>
                  <button className='button is-info'
                    onClick={calculateComparison}
                    disabled={!isReadyToCalculate()}>
                    {t('act-button.calculate')}
                  </button>
                </div>
                <div className='block'>
                  <button className='button is-info back-button'
                    onClick={() => { navigate(-1 - stepperSwitchCount) }}>
                    {t('nav-button.back')}
                  </button>
                </div>
              </aside>
            </div>
            <div className='column is-3'>
              <Outlet context={contextData} />
            </div>
            <div className='column'>
              {loading &&
                <div className="spinner">
                  <ClipLoader color={c.CYCLOPS_BLUE}
                    loading={loading}
                    cssOverride={{
                      display: "block",
                      margin: "0 auto"
                    }} size={100} />
                </div>
              }
              {result &&
                <>
                  <div className="container" id="chart-container">
                    <ComparisonChart
                      items={result}
                      comparisonSubjects={comparisonSubjects}
                    />
                  </div>
                  <div className="notification chart-explain">
                    {t('ecol-assess.explanation')}
                  </div>
                  <div className="container" id="distribution-chart-container">
                    <ImpactDistributionChart
                      labels={Object.keys(techFlowIdByRecycleProcName)}
                      input={allContributionImpactData["Climate change"]} />
                  </div>
                  <button
                    className='button is-info save-dashboard block'
                    onClick={saveEcologicalChartData}>
                    {t('act-button.save-dashboard')}
                  </button>
                  <SaveAlert
                    visible={saveAlertVisible}
                    setVisible={setSaveAlertVisible}
                  />
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
