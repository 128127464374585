import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cyclopsLogo from '../images/cyclops-logo.png';
import c from '../utils/constants';

export const Header = () => {
  const { t, i18n } = useTranslation();

  const setLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <nav className="navbar is-fixed-top has-shadow" role="navigation"
      aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img src={cyclopsLogo} />
        </Link>

        <a role="button" className="navbar-burger" aria-label="menu"
          aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          <Link className="navbar-item" to="/">
            Home
          </Link>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <label className="label">
              {t('header.language.label')} &nbsp;
            </label>
            <div className="select is-primary">
              <select onChange={setLanguage}>
                <option value={c.EN}>{t('header.language.en')}</option>
                <option value={c.DE}>{t('header.language.de')}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
