import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const BuyPlasticWaste = () => {
  const { t } = useTranslation();

  return (
    <div className='body-wrapper body-padding' id='buy-plastic-waste-container'>
      <h3 className='title is-1 has-text-primary'>
        {t('buy-plastic-waste.title')}
      </h3>
      <div className='flex-break' />
      <div className='box'>
        <div className='content'>
          <p>{t('buy-plastic-waste.p1')}</p>
          <ol>
            <li>
              {t('buy-plastic-waste.li1')}
            </li>
            <li>
              {t('buy-plastic-waste.li2')}
            </li>
          </ol>
          <p>
            {t('buy-plastic-waste.p2')}
          </p>
        </div>
      </div>
      <div className='flex-break' />
      <div className="nav-button-wrapper">
        <Link to='/ecological-assessment'>
          <button className='button is-medium nav-button
            is-responsive ecol-assess'
          >
            {t('tool-button.ecol-assess')}
          </button>
        </Link>
      </div>
      <div className='flex-break' />
      <div className="nav-button-wrapper">
        <Link to='/waste-matrix'>
          <button className='button is-medium nav-button
            is-responsive waste-matrix'
          >
            {t('tool-button.waste-matrix')}
          </button>
        </Link>
      </div>
      <div className='flex-break' />
      <div className='dashboard-button'>
        <Link to='/dashboard'>
          <button className='button is-medium nav-button
            is-responsive'
          >
            {t('tool-button.dashboard1')}
            <br />
            {t('tool-button.dashboard2')}
          </button>
        </Link>
      </div>
    </div>
  );
};
