import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ConverterTools = () => {
  const { t } = useTranslation();

  return (
    <div className='body-wrapper body-padding' id='buy-recyclates-container'>
      <h3 className='title is-1 has-text-primary'>
        {t('converter.title')}
      </h3>
      <div className='flex-break' />
      <div className='box'>
        <div className='content'>
          <p>{t('converter.p1')}</p>
          <ol>
            <li>
              {t('converter.li1')}
            </li>
            <li>
              {t('converter.li2')}
            </li>
            <li>
              {t('converter.li3')}
            </li>
          </ol>
          <p>
            {t('converter.p2')}
          </p>
        </div>
      </div>
      <div className='flex-break' />
      <div className='nav-button-wrapper'>
        <Link to=''>
          <button className='button is-medium nav-button
            is-responsive price-prediction'>
            {t('tool-button.price-predict')}
          </button>
        </Link>
      </div>
      <div className='flex-break' />
      <div className='nav-button-wrapper'>
        <Link to='/design-recycling'>
          <button className='button is-medium nav-button
            is-responsive design-for-recycling'>
            {t('tool-button.design-recycling')}
          </button>
        </Link>
      </div>
      <div className='flex-break' />
      <div className='nav-button-wrapper'>
        <Link to='/converter-ecological-assessment'>
          <button className='button is-medium nav-button
          is-responsive ecol-assess'>{t('tool-button.ecol-assess')}</button>
        </Link>
      </div>
      <div className='flex-break' />
      <div className='dashboard-button'>
        <Link to='/dashboard'>
          <button className='button is-medium nav-button
              is-responsive'>
              {t('tool-button.dashboard1')}
              <br />
              {t('tool-button.dashboard2')}
          </button>
        </Link>
      </div>
    </div>
  );
};
