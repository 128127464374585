import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState
} from 'react';
import { inputFieldChange, setPlasticFieldAttribute }
  from '../utils/inputFieldChange';
import { InputNumber } from './InputNumber';
import { PlasticInput } from './PlasticInput';
import { useDinspecOptions } from '../model/useDinspecOptions';
import { MaterialDropdown } from './MaterialDropdown';
import { BuyerMaterial } from '../model/materials';
import * as materials from "../model/materials";
import { EconomicAssessmentChart } from './EconomicAssessmentChart';
import { EconParamInfluenceChart } from './EconParamInfluenceChart';
import { usePlasticFields, PlasticFields } from '../model/usePlasticFields';
import {
  EcoAssessDataItem,
  EcoAssessPlotData
} from '../model/economicAssessment';
import {
  createHoverTextList,
  createPriceListDisplayAndRepetitionCount,
  generatePriceFluctuationPlotData,
  getMarkerSizeListByAmountRepetition,
  preparePlotLayout
} from '../utils/economicAssessment';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Layout } from 'plotly.js';
import { Select } from './Select';
import { useEconAssessParamOptions } from '../model/useEconAssessParamOptions';
import { useTranslation } from 'react-i18next';
import { useColourOptions } from '../model/useColourOptions';
import { useSourceOptions } from '../model/useSourceOptions';
import { useFrequencyOptions } from '../model/useFrequencyOptions';
import { useRecurringOptions } from '../model/useRecurringOptions';
import { SaveAlert } from './SaveAlert';
import { EconAssessParamValuePair } from '../model/economicAssessment';
import { OptionItem, Stakeholder } from '../model/model';
import constants from '../utils/constants';
import dashboardDataManager from '../service/DashboardDataManager';
import stakeholderData from '../service/StakeholderData';
import sampleEconData from '../data/sampleEconData.json';

export const MAX_MARKER_SIZE = 22;

export const EconomicAssessment = () => {
  const { t, i18n } = useTranslation();
  const { NO_INPUT, RECURRING, DIN_SPEC, COLOR, FREQUENCY,
    AMOUNT_IN_TON, EN, GREY_BORDER, COLOR_SCALE, OPACITY } = constants;
  const econAssessParamOptions = useEconAssessParamOptions();
  const initialState = usePlasticFields();
  const residueFromOptions = useSourceOptions();

  const recurringOptions = useRecurringOptions();
  const recurringOffers: EconAssessParamValuePair[] =
    getEconAssessParamValuePair(recurringOptions);

  const dinSpecOptions = useDinspecOptions();
  const dinSpecs: EconAssessParamValuePair[] =
    getEconAssessParamValuePair(dinSpecOptions);

  const colourOptions = useColourOptions();
  const colors: EconAssessParamValuePair[] =
    getEconAssessParamValuePair(colourOptions);

  const frequencyOptions = useFrequencyOptions();
  const frequencies: EconAssessParamValuePair[] =
    getEconAssessParamValuePair(frequencyOptions);

  const [plasticFields, setPlasticFields]
    = useState<PlasticFields>(initialState);
  const [materialCompositionLeft, setMaterialCompositionLeft]
    = useState<null | number>();
  const [materialCompositionRight, setMaterialCompositionRight]
    = useState<null | number>();
  const [materialCompositionRightWarning, setMaterialCompositionRightWarning]
    = useState(false);
  const [materialCompositionUnknown, setMaterialCompositionUnknown]
    = useState(false);
  const [economicChartVisible, setEconomicChartVisible] = useState(false);
  const [sampleData, setSampleData] = useState<EcoAssessDataItem[]>(sampleEconData);
  const [saveConfirmationVisible, setSaveConfirmationVisible] = useState(false);
  const [ecoAssessParam, setEcoAssessParam] = useState(NO_INPUT);
  const [inPlotData, setInPlotData] = useState<Plotly.Data[]>();
  const [plotLayout, setPlotLayout] = useState<Partial<Layout>>();
  const [touched, setTouched] = useState(false);
  const [saveAlertVisible, setSaveAlertVisible] = useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTouched(true);
    setSaveConfirmationVisible(false);
  }, [
    plasticFields.salesValue,
    plasticFields.materialType,
    plasticFields.materialAmount,
    plasticFields.dinSpec,
    plasticFields.recurring,
    plasticFields.colour,
    plasticFields.residueFrom
  ]);

  useEffect(() => {
    if (!i18n.language)
      return;
    handleEnDeLangSwitch("materialType");
    handleEnDeLangSwitch("mixComponent1");
    handleEnDeLangSwitch("mixComponent2");
  }, [i18n.language]);

  function getEconAssessParamValuePair(options: OptionItem[]) {
    return options.filter(item => item.value !== NO_INPUT)
      .map(item => ({
        uiValue: item.text,
        codeValue: item.value
      }));
  }

  const handleEnDeLangSwitch = (fieldName: string) => {
    const enDeMapping = {
      [NO_INPUT]: "Keine Eingabe",
      "Material mix": "Materialmischung"
    }
    const currentFieldValue =
      plasticFields[fieldName as keyof typeof plasticFields];
    let newFieldValue = "";
    if (i18n.language === EN) {
      for (const pair of Object.entries(enDeMapping))
        if (pair[1] === currentFieldValue)
          newFieldValue = pair[0];
    } else
      newFieldValue = enDeMapping[
        currentFieldValue as keyof typeof enDeMapping
      ];
    if (!newFieldValue)
      return;
    setPlasticFieldAttribute(fieldName, newFieldValue, setPlasticFields);
  };

  const materialCompositionLeftOrRightChange = (
    event: ChangeEvent<HTMLInputElement>,
    isRight: boolean
  ) => {
    const value = parseFloat(event.target.value);
    let composition;
    if (isRight) {
      setMaterialCompositionRight(value);
      setMaterialCompositionRightWarning(isNaN(value) || !value);
      composition = value && materialCompositionLeft ?
        materialCompositionLeft / value : 0;
    } else {
      setMaterialCompositionLeft(value);
      composition = value && materialCompositionRight ?
        value / materialCompositionRight : 0;
    }
    setPlasticFieldAttribute(
      "materialComposition", composition, setPlasticFields
    );
  };

  const materialCompositionUnknownChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const composition =
      materialCompositionUnknown &&
        materialCompositionLeft &&
        materialCompositionRight ?
        materialCompositionLeft / materialCompositionRight : 0;
    setPlasticFieldAttribute(
      "materialComposition", composition, setPlasticFields
    );
    setMaterialCompositionUnknown(!materialCompositionUnknown);
  };

  const filterMixComponentOptions = (isMixComponent1: boolean) => {
    const excludedValue = isMixComponent1 ?
      plasticFields.mixComponent2 : plasticFields.mixComponent1;
    const excludedMaterial =
      JSON.parse(JSON.stringify(materials.BUYER_MATERIALS)) as BuyerMaterial[];

    for (const material of excludedMaterial) {
      material.flows.forEach((flow, index) => {
        if (flow.label === excludedValue) {
          material.flows.splice(index, 1);
        }
      });
    }

    return excludedMaterial;
  };

  const getMaterialComponentInput = (
    className: string,
    placeholder: string,
    value: number | null | undefined,
    onChange: ChangeEventHandler<HTMLInputElement>,
    disabled: boolean
  ) => {
    return (
      <div className='column is-2'>
        <input type="number" className={className}
          placeholder={placeholder}
          value={value ? value : 0}
          onChange={onChange}
          disabled={disabled} />
      </div>
    );
  };

  const showEconomicChart = () => {
    setEconomicChartVisible(true);
  };

  const saveEconomicChartData = () => {
    const dashboardData = dashboardDataManager
      .getDataByStakeholder(stakeholderData.stakeholder as Stakeholder);
    const econDataManager = dashboardData.econAssessChartData;

    // store plastic inputs that users choose for
    // showing later on Dashboard page
    econDataManager.setPlasticFields(plasticFields);

    // store economic sample data for showing later
    // on Dashboard page. This sample data includes
    // material price distribution chart
    econDataManager.setSampleData(sampleData);

    // store economic data for showing later
    // on Dashboard page. This data includes
    // price influence by parameter chart
    econDataManager.setChosenParameter(ecoAssessParam);
    econDataManager.setInPlotData(inPlotData);
    econDataManager.setPlotLayout(plotLayout);

    setTouched(false);
    setSaveConfirmationVisible(true);
    setSaveAlertVisible(true);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotification(false);
  };

  const prepareInPlotData = (data: EcoAssessPlotData[]) => {
    const inPlotData: Plotly.Data[] = [];

    data.forEach((item, index) => {
      const priceList = item.data.map(obj => obj.price);
      const [priceListDisplay, repetitionCount] =
        createPriceListDisplayAndRepetitionCount(priceList);
      const hoverTextList =
        createHoverTextList(priceListDisplay, repetitionCount, t);
      const sizeList =
        getMarkerSizeListByAmountRepetition(repetitionCount, MAX_MARKER_SIZE);

      const marker: any = {
        size: sizeList,
        opacity: OPACITY,
        color: repetitionCount,
        colorscale: COLOR_SCALE,
        reversescale: true,
        line: {
          color: GREY_BORDER,
          width: 1
        },
      };

      if (index === data.length - 1) {
        marker.showscale = true;
        marker.colorbar = {
          title: t('chart.amount')
        };
      }

      inPlotData.push({
        x: priceListDisplay,
        y: item.data.map(() => item.paramValue),
        type: 'scatter',
        mode: 'markers',
        marker: marker,
        hoverinfo: 'text',
        text: hoverTextList,
      });
    });

    setInPlotData(inPlotData);
    preparePlotLayout(setPlotLayout, t, t('chart.price-euro') as string);
  };

  const generatePriceInfluencePlotData = (
    param: string,
    paramValues: EconAssessParamValuePair[]
  ) => {
    if (!sampleData || sampleData.length === 0)
      return;

    const mapping = {
      [DIN_SPEC]: "dinSpec",
      [RECURRING]: "recurringOffer",
      [COLOR]: "color",
      [FREQUENCY]: "frequency"
    };

    const attributeName = mapping[param as keyof typeof mapping];
    const priceInfluencePlotData = paramValues.map(valuePair => {
      return {
        paramValue: valuePair.uiValue,
        data: sampleData.filter(
          item => item[attributeName as keyof typeof item] === valuePair.codeValue
        )
      }
    });
    return priceInfluencePlotData;
  };

  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    let plotData = [];
    const parameter = event.target.value;
    setEcoAssessParam(parameter);
    if (parameter === NO_INPUT)
      return;
    if (parameter === AMOUNT_IN_TON) {
      generatePriceFluctuationPlotData(
        sampleData as EcoAssessDataItem[],
        t,
        setPlotLayout,
        setInPlotData
      );
    } else {
      const ecoParamMapping = {
        [DIN_SPEC]: dinSpecs,
        [RECURRING]: recurringOffers,
        [COLOR]: colors,
        [FREQUENCY]: frequencies
      };
      plotData = generatePriceInfluencePlotData(
        parameter,
        ecoParamMapping[parameter as keyof typeof ecoParamMapping]
      ) as EcoAssessPlotData[];
      prepareInPlotData(plotData);
    }
  };

  return (
    <div className="body-padding">
      <div className='container' id='economic-assessment-container'>
        <h1 className="title is-1 has-text-primary">{t('econ-assess.title')}</h1>
        <h3 className='title is-3 has-text-primary'>{t('econ-assess.subtitle')}</h3>
        <div className='columns'>
          <div className='column is-5'>
            <InputNumber label={t('econ-assess.sales-value')}
              width={'is-7'}
              value={plasticFields.salesValue as number}
              onChange={value => setPlasticFieldAttribute(
                "salesValue", value, setPlasticFields)}
              unit={`€/${t('ton')}`}
            />
            <div className='field'>
              <label className="label">{t('econ-assess.material-type')}</label>
              <div className="columns is-mobile">
                <div className="column is-7">
                  <MaterialDropdown
                    value={plasticFields.materialType as string}
                    options={materials.BUYER_MATERIALS}
                    onDropdownSelect={
                      (e) => setPlasticFieldAttribute(
                        "materialType", e.currentTarget.innerText,
                        setPlasticFields
                      )
                    }
                    hasMaterialMix={true} />
                </div>
              </div>
            </div>
            {plasticFields.materialType === t('econ-assess.material-mix') ?
              <>
                <label className="label">{t('econ-assess.mix-component1')}</label>
                <div className="columns is-mobile">
                  <div className="column is-7">
                    <MaterialDropdown value={
                      plasticFields.mixComponent1 as string
                    }
                      options={filterMixComponentOptions(true)}
                      onDropdownSelect={
                        (e) => setPlasticFieldAttribute(
                          "mixComponent1", e.currentTarget.innerText,
                          setPlasticFields
                        )
                      } />
                  </div>
                </div>
                <label className="label">{t('econ-assess.mix-component2')}</label>
                <div className="columns is-mobile">
                  <div className="column is-7">
                    <MaterialDropdown
                      value={plasticFields.mixComponent2 as string}
                      options={filterMixComponentOptions(false)}
                      onDropdownSelect={
                        (e) => setPlasticFieldAttribute(
                          "mixComponent2", e.currentTarget.innerText,
                          setPlasticFields
                        )
                      } />
                  </div>
                </div>

                <label className="label">{t('econ-assess.composition-ratio')}</label>
                <div className='columns is-mobile'>
                  {getMaterialComponentInput(
                    "input is-primary has-text-right",
                    `${t('econ-assess.component')} #1`,
                    materialCompositionLeft,
                    (e) => materialCompositionLeftOrRightChange(e, false),
                    materialCompositionUnknown)}
                  <div className='column is-1 has-text-centered'>
                    <p className='control mt-2'>:</p>
                  </div>
                  {getMaterialComponentInput(
                    "input is-primary" +
                    (materialCompositionRightWarning ? " is-danger" : ""),
                    `${t('econ-assess.component')} #2`,
                    materialCompositionRight,
                    (e) => materialCompositionLeftOrRightChange(e, true),
                    materialCompositionUnknown)}
                </div>

                <input type="checkbox"
                  className="mr-1"
                  checked={materialCompositionUnknown}
                  onChange={materialCompositionUnknownChange} />
                {t('econ-assess.composition-unknown')}
              </>
              : <></>
            }

            <InputNumber label={t('econ-assess.material-amount')}
              width={'is-7'}
              value={plasticFields.materialAmount as number}
              onChange={value => setPlasticFieldAttribute(
                "materialAmount", value, setPlasticFields)}
              unit={t('ton')}
            />

            <PlasticInput
              label={t('econ-assess.din-spec') as string}
              selectValue={plasticFields.dinSpec}
              onSelectChange={(e) => inputFieldChange(
                e, "dinSpec", setPlasticFields)}
              options={dinSpecOptions}
            />

            <PlasticInput
              label={t('econ-assess.offer-frequency') as string}
              selectValue={plasticFields.recurring}
              onSelectChange={(e) => inputFieldChange(
                e, "recurring", setPlasticFields)}
              options={recurringOptions}
            />

            {plasticFields.recurring &&
              plasticFields.recurring === RECURRING &&
              <PlasticInput
                label={t('econ-assess.frequency') as string}
                selectValue={plasticFields.frequency}
                onSelectChange={(e) => inputFieldChange(
                  e, "frequency", setPlasticFields)}
                options={frequencyOptions}
              />
            }

            <PlasticInput
              label={t('econ-assess.colour') as string}
              selectValue={plasticFields.colour}
              onSelectChange={(e) => inputFieldChange(
                e, "colour", setPlasticFields)}
              options={colourOptions}
            />

            <PlasticInput
              label={t('econ-assess.source') as string}
              selectValue={plasticFields.residueFrom}
              onSelectChange={(e) => inputFieldChange(
                e, "residueFrom", setPlasticFields)}
              options={residueFromOptions}
            />
            <div className='block'>
              <button className='button is-info' onClick={showEconomicChart}>
                {t('econ-assess.next')}
              </button>
            </div>
            <div className='block'>
              <button
                className='button is-info back-button'
                onClick={() => { navigate(-1) }}
              >
                {t('nav-button.back')}
              </button>
            </div>
          </div>
          <div className='column'>
            {economicChartVisible &&
              <div className="container"
                id="economic-assessment-plot-container">
                <div className='block'>
                  <EconomicAssessmentChart
                    sampleData={sampleData}
                    setSampleData={setSampleData}
                    plasticFields={plasticFields}
                    setOpenNotification={setOpenNotification}
                  />
                </div>
                <div className='block'>
                  <label className="label">
                    {t('econ-assess.param-influence-price1')}
                  </label>
                  <div className='parameter-influence-select'>
                    <Select value={ecoAssessParam}
                      onSelectChange={onSelectChange}
                      options={econAssessParamOptions} />
                  </div>
                </div>
                <div className='block'>
                  <EconParamInfluenceChart
                    inPlotData={inPlotData}
                    plotLayout={plotLayout}
                  />
                </div>
                <button disabled={!touched}
                  className='button is-info block'
                  onClick={saveEconomicChartData}
                >
                  {t('act-button.save-dashboard')}
                </button>
                <SaveAlert
                  visible={saveAlertVisible}
                  setVisible={setSaveAlertVisible}
                />
                {saveConfirmationVisible &&
                  <div className="notification block">
                    {t('econ-assess.save-dashboard-complete')}
                  </div>
                }
              </div>
            }
          </div>
        </div>
        <Snackbar
          open={openNotification}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
            {t('econ-assess.alert1')}
            <br />
            {t('econ-assess.alert2')}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};
