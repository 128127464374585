//manual mapping between process name and parameter object
//for exceptional cases when process name and parameter name don't match
export const exceptionProcessParamPairs = {
  "Generic data (Non-machine-specific shredding)": {
    "name": "shredding_generic",
    "value": 0.0,
    "context": {
      "@type": "Process",
      "@id": "caa407c1-f97f-40d0-9dbb-616b0e6effc0",
      "name": "Non-machine-specific shredding"
    }
  },
  "Generic data (Non-machine-specific washing)": {
    "name": "washing_generic",
    "value": 0.0,
    "context": {
      "@type": "Process",
      "@id": "a3c6c9da-be99-4b98-ae6d-76209b9fd654",
      "name": "Wash"
    }
  },
  "Generic data (Non-machine-specific sorting/separation)": {
    "name": "sorting_generic",
    "value": 0.0,
    "context": {
      "@type": "Process",
      "@id": "2f1afae9-bcb4-4135-9a53-ebb378943822",
      "name": "Non-machine-specific sorting/separation"
    }
  },
  "Generic data (Non-machine-specific pelletizing)": {
    "name": "pelletizing_generic",
    "value": 0.0,
    "context": {
      "@type": "Process",
      "@id": "d924d9e5-d6e2-4283-8000-cb7d911ee5db",
      "name": "Non-machine-specific pelletizing"
    }
  },
  "Generic data (Non-machine-specific input)": {
    "name": "opening_dissolving_generic",
    "value": 0.0,
    "context": {
      "@type": "Process",
      "@id": "35c05c34-3fa5-4323-a9f3-b5538770de97",
      "name": "Non-machine-specific input"
    }
  },
  "Generic data (Non-machine-specific drying)": {
    "name": "drying_generic",
    "value": 0.0,
    "context": {
      "@type": "Process",
      "@id": "7a94e0b1-0cff-40c4-99ba-f4c5523cc4e4",
      "name": "Non-machine-specific drying"
    }
  },
  "Generic data (Non-machine-specific agglomeration)": {
    "name": "compression_generic",
    "value": 0.0,
    "context": {
      "@type": "Process",
      "@id": "24911af3-116d-496a-a682-392ac46bc978",
      "name": "Agglomerate & compress"
    }
  }
};

export const filteredOutProcesses = [
  "Wash",
  "Sort & separate",
  "Sort & separate (Xi)",
  "Shred", "Shred (Xi) ",
  "Pelletizers & combined systems (Xi)",
  "Pelletizers & combined systems",
  "Open & Dissolve",
  "Open & Dissolve (Xi)",
  "Dry (Xi)", "Drying",
  "Mechanical dryer",
  "Thermal dryer",
  "Vertical dryer",
  "Step dryer",
  "Press dryer",
  "Screw press dryer",
  "Centrifugal dryer",
  "Vacuum dryer",
  "Bag opener",
  "Bale dissolver",
  "Single-screw pelletizer",
  "Water ring pelletizer",
  "Other pelletrizer",
  "Twin-screw pelletizer",
  "Single-shaft shredders",
  "Twin-shaft shredders",
  "Foil-shredders",
  "Plastic shredders",
  "Granulators",
  "Millers & grinders",
  "Fine grinders",
  "Side grinders",
  "Hammer mills",
  "Pipe shredders",
  "Agglomerate & compress",
  "Vibration separation",
  "Ballistic separation",
  "Miscellaneous",
  "Label separation",
  "Tank separation",
  "Impurity separation",
  "Hydrocyclone separation",
  "Air separation",
  "Disc separation",
  "Magnetic separation",
  "Eddy current separation",
  "Color sorting"
];

//remove all special characters from a string, convert string to lowercase
//replace some characters to their English alternatives
export const sanitizeAndMinimizeString = (aString: string) => {
  const regEx = /[^a-z0-9öäü]/ig;
  return aString.toLowerCase()
    .replace(" x ", "")
    .replaceAll(regEx, "")
    .replace("ö", "o")
    .replace("ä", "a")
    .replace("ü", "u");
};
