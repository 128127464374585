import { Layout } from "plotly.js";
import { EcoAssessDataItem } from "../model/economicAssessment";
import { PlasticFields } from "../model/usePlasticFields";

class EconAssessChartData {
  sampleData: EcoAssessDataItem[] | undefined;
  plasticFields: PlasticFields | undefined;
  chosenParameter: string | undefined;
  inPlotData: Plotly.Data[] | undefined;
  plotLayout: Partial<Layout> | undefined;

  setSampleData(sampleData: EcoAssessDataItem[] | undefined) {
    this.sampleData = sampleData;
  }

  setPlasticFields(plasticFields: PlasticFields | undefined) {
    this.plasticFields = plasticFields;
  }

  setChosenParameter(param: string | undefined) {
    this.chosenParameter = param;
  }

  setInPlotData(inPlotData: Plotly.Data[] | undefined) {
    this.inPlotData = inPlotData;
  }

  setPlotLayout(plotLayout: Partial<Layout> | undefined) {
    this.plotLayout = plotLayout;
  }
}

export default EconAssessChartData;
