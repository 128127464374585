import { useTranslation } from "react-i18next";
import { OptionItem } from "./model";
import constants from "../utils/constants";

export const useEconAssessParamOptions = () => {
  const { t } = useTranslation();
  const { DIN_SPEC, RECURRING, COLOR, FREQUENCY,
    AMOUNT_IN_TON, NO_INPUT } = constants;

  const options: OptionItem[] = [
    { text: t('econ-assess.din-spec'), value: DIN_SPEC },
    {
      text: t('recurring-options.recurring'),
      value: RECURRING
    },
    { text: t('econ-assess.colour'), value: COLOR },
    { text: t('econ-assess.frequency'), value: FREQUENCY },
    { text: t('chart.amount-ton'), value: AMOUNT_IN_TON },
    { text: t('no-input'), value: NO_INPUT }
  ];

  return options;
};
