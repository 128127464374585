import React from 'react';

export const InputNumber = ({
  label,
  width,
  value,
  onChange,
  unit,
}: {
  label: string;
  width?: string;
  value: number;
  onChange: (next: number) => void;
  unit: string,
}) => {
  return (
    <div className='field'>
      <label className="label">{label}</label>
      <div className="columns is-mobile">
        <div className={"column " + width}>
          <input className="input is-primary"
            type="number"
            placeholder=""
            value={value}
            onChange={e => onChange(parseFloat(e.target.value))}
            required min="0"
          />
        </div>
        <div className='column is-1'>
          <p className='is-primary'>{unit}</p>
        </div>
      </div>
    </div>
  );
};
