export const switchArrayItemPosition = (
  fromIndex: number,
  toIndex: number,
  array: string[] | number[]
) => {
  const temp = array[fromIndex];
  array[fromIndex] = array[toIndex];
  array[toIndex] = temp;
};

export const indexOfLongestLabel = (labels: string[]) => {
  let longest = 0;

  for (const label of labels) {
    if (label.length > longest)
      longest = label.length;
  }

  for (let i = 0; i < labels.length; i++) {
    if (labels[i].length === longest)
      return i;
  }
  return 0;
};
