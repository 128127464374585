import React, { MouseEvent, useEffect } from 'react';
import { OpenLCAProcess, ParameterRedef, ProcessCategory }
  from '../model/model';
import { NestedDropdown } from './NestedDropdown';
import { exceptionProcessParamPairs, sanitizeAndMinimizeString }
  from "../utils/buy";
import * as api from "../model/api";
import { useBuyerMaterial } from './EcologicalAssessment';
import { useTranslation } from 'react-i18next';
import { handleFieldValueLangSwitch } from './BuyerGeneralData';
import c from '../utils/constants';

export const BuyerRecyclingSteps = () => {
  const { t, i18n } = useTranslation();
  const buyerMaterial = useBuyerMaterial();
  const allStepStates = buyerMaterial.allStepStates;
  const [processCategories, setProcessCategories] =
    buyerMaterial.processCategories;
  const [processParameterPairs, setProcessParameterPairs] =
    buyerMaterial.processParameterPairs;
  const [recyclingParameters, setRecyclingParameters] =
    buyerMaterial.recyclingParameters;
  const [recyclingProcesses, setRecyclingProcesses] =
    buyerMaterial.recyclingProcesses;

  const recyclingProcessCategory = "Recycling incl. AP3/Recyclingprozesse";
  const buyerProductSystemId = "7c064f77-5dc6-462e-ae4c-8d2eea3cd45a";
  const apiEndpoint =
    `/data/product-system/${buyerProductSystemId}/parameters`;

  const loadParameters = async () => {
    const allParams: ParameterRedef[] =
      (await api.get(apiEndpoint)) as ParameterRedef[];
    const params = allParams.filter((p) => {
      if (!p.context || !p.context.category)
        return false;
      return p.context.category.startsWith(recyclingProcessCategory);
    });
    setRecyclingParameters(params);
  };

  const matchParametersWithProcess = () => {
    let processParamPairs: { [key: string]: ParameterRedef } = {};

    for (const process of recyclingProcesses) {
      const processName = sanitizeAndMinimizeString(process.name);

      for (const param of recyclingParameters) {
        const paramName = sanitizeAndMinimizeString(param.name);
        if (processName === paramName)
          processParamPairs[process.name] = param;
      }
    }

    processParamPairs = {
      ...processParamPairs,
      ...exceptionProcessParamPairs
    };
    setProcessParameterPairs(processParamPairs);
  };

  const loadRecyclingProcesses = async () => {
    const allProcesses: OpenLCAProcess[] =
      (await api.get("/data/process")) as OpenLCAProcess[];
    const processes = allProcesses.filter(
      (p) => p.category.startsWith(recyclingProcessCategory)
    );
    setRecyclingProcesses(processes);
  };

  const onDropdownSelect = (
    event: MouseEvent<HTMLAnchorElement>,
    stepName: keyof typeof allStepStates
  ) => {
    allStepStates[stepName].setState(event.currentTarget.innerText);
  };

  const getDisplayNameOfProcessCategory = (category: string) => {
    const categoryParts = category.split("/");
    return categoryParts[categoryParts.length - 1];
  };

  const buildProcessCategoryModel = (category: string) => {
    const name = getDisplayNameOfProcessCategory(category);
    const model: ProcessCategory = {
      title: name,
      category: category,
      subProcesses: [],
      subCategories: []
    };
    const subCatPaths: string[] = [];
    //gather together processes of this category
    for (const process of recyclingProcesses) {
      if (process.category === category) {
        model.subProcesses.push(process);
      }
    }

    for (const process of recyclingProcesses) {
      //detect if this category has direct sub-categories
      const categoryPartsCount = process.category.split("/").length;
      const currentCatPartsCount = category.split("/").length;
      if (process.category.includes(category) &&
        categoryPartsCount - currentCatPartsCount === 1) {
        //get a list of non-duplicated sub-category paths from processes
        if (!subCatPaths.includes(process.category)) {
          subCatPaths.push(process.category);
        }
      }
    }
    //call buildProcessCategoryModel() recursively for each sub-category
    //to create sub-category objects and gather them together
    //for this category object
    for (const subCategory of subCatPaths) {
      model.subCategories?.push(buildProcessCategoryModel(subCategory));
    }

    return model;
  };

  const convertSubCatToGerman = (title: string) => {
    if (i18n.language === c.DE) {
      const titleMapping = {
        [c.WASH]: t('ecol-assess.recycling-step.wash'),
        [c.OPEN_DISSOLVE]: t('ecol-assess.recycling-step.open-dissolve'),
        [c.AGGLOMERATE_COMPRESS]: t('ecol-assess.recycling-step.agglomerate-compress'),
        [c.SORT_SEPARATE]: t('ecol-assess.recycling-step.sort-separate'),
        [c.SHRED]: t('ecol-assess.recycling-step.shred'),
        [c.PELLETIZERS]: t('ecol-assess.recycling-step.pelletizer'),
        [c.DRY]: t('ecol-assess.recycling-step.dry')
      };
      return titleMapping[title as keyof typeof titleMapping];
    } else {
      return title;
    }
  };

  const areSubCategoriesAvailable = () => {
    return processCategories && processCategories?.subCategories &&
      processCategories.subCategories.length > 0;
  };

  useEffect(() => {
    if (Object.entries(processParameterPairs).length <= 0 &&
      recyclingProcesses.length > 0 &&
      recyclingParameters.length > 0)
      matchParametersWithProcess();
  }, [processParameterPairs, recyclingProcesses, recyclingParameters]);

  useEffect(() => {
    if (recyclingProcesses.length <= 0) {
      loadRecyclingProcesses();
    } else {
      setProcessCategories(
        buildProcessCategoryModel(recyclingProcessCategory)
      );
    }
  }, [recyclingProcesses]);

  useEffect(() => {
    if (recyclingParameters.length <= 0) {
      loadParameters();
    }
  }, [recyclingParameters]);

  useEffect(() => {
    if (!i18n.language)
      return;
    for (const key in allStepStates) {
      const { state, setState } = allStepStates[key]
      handleFieldValueLangSwitch(state, setState, i18n);
    }
  }, [i18n.language]);

  return (
    <div>
      {areSubCategoriesAvailable() &&
        processCategories.subCategories.map(subCategory =>
          <div className='field' key={subCategory.title}>
            <label className="label">
              {convertSubCatToGerman(subCategory.title)}
            </label>
            <NestedDropdown data={subCategory}
              value={
                allStepStates[
                  subCategory.title as keyof typeof allStepStates
                ].state
              }
              onDropdownSelect={(e) => {
                onDropdownSelect(
                  e, subCategory.title as keyof typeof allStepStates
                )
              }} />
          </div>
        )
      }
      <label className="checkbox">
        <input type="checkbox" />&nbsp;
        {t('ecol-assess.recycling-step.use-renew-energy')}
      </label>
    </div>
  );
};
