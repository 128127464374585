import { useTranslation } from "react-i18next";
import { MultiValue } from "react-select";
import { OptionItem } from "./model";
import constants from "../utils/constants";

export const useDesign4RecyclingOptions = () => {
  const { t } = useTranslation();
  const { NO_INPUT } = constants;

  const recommendations: MultiValue<{
    value: string;
    label: string;
  }> = [
      {
        label: t('design-recycling.recommend-category.material-additive'),
        value: "design-recycling.recommend-category.material-additive"
      },
      {
        label: t('design-recycling.recommend-category.material-colour'),
        value: "design-recycling.recommend-category.material-colour"
      },
      {
        label: t('design-recycling.recommend-category.inks-packaging'),
        value: "design-recycling.recommend-category.inks-packaging"
      },
      {
        label: t('design-recycling.recommend-category.emptying-capability'),
        value: "design-recycling.recommend-category.emptying-capability"
      },
      {
        label: t('design-recycling.recommend-category.sealing-parts'),
        value: "design-recycling.recommend-category.sealing-parts"
      },
      {
        label: t('design-recycling.recommend-category.consumer-measure'),
        value: "design-recycling.recommend-category.consumer-measure"
      }
    ];

  const wasteTypes: OptionItem[] = [
    {
      text: t('waste-matrix.packaging-food'),
      value: "Packaging/food"
    },
    {
      text: t('waste-matrix.other-packaging'),
      value: "Packaging/other"
    },
    {
      text: t('design-recycling.package-industrial'),
      value: "Packaging/industrial"
    },
    {
      text: t('waste-matrix.const-indoor'),
      value: "Construction/indoor"
    },
    {
      text: t('waste-matrix.const-outdoor'),
      value: "Construction/outdoor"
    },
    {
      text: t('waste-matrix.auto-indoor'),
      value: "Automotive/indoor"
    },
    {
      text: t('waste-matrix.auto-outdoor'),
      value: "Automotive/outdoor"
    },
    {
      text: t('waste-matrix.electronics'),
      value: "Electronics"
    },
    {
      text: t('waste-matrix.agriculture'),
      value: "Agriculture"
    },
    {
      text: t('design-recycling.house-appliance-food'),
      value: "Household appliances/food"
    },
    {
      text: t('waste-matrix.other-appliance'),
      value: "Household appliances/other"
    },
    {
      text: t('waste-matrix.furniture-indoor'),
      value: "Furniture/indoor"
    },
    {
      text: t('waste-matrix.furniture-outdoor'),
      value: "Furniture/outdoor"
    },
    {
      text: t('waste-matrix.medicine'),
      value: "Medicine"
    },
    {
      text: t('no-input'),
      value: NO_INPUT
    }
  ];

  const recommendationsByWasteType = (wasteType: string): { [key: string]: string[] } => {
    if (wasteType.startsWith("Packaging"))
      return {
        "design-recycling.recommend-category.material-additive": [
          'design-recycling.recommendation-lines.material-additive.1',
          'design-recycling.recommendation-lines.material-additive.2',
          'design-recycling.recommendation-lines.material-additive.3',
          'design-recycling.recommendation-lines.material-additive.4',
          'design-recycling.recommendation-lines.material-additive.5',
          'design-recycling.recommendation-lines.material-additive.7',
          'design-recycling.recommendation-lines.material-additive.8',
          'design-recycling.recommendation-lines.material-additive.9',
          'design-recycling.recommendation-lines.material-additive.10',
          'design-recycling.recommendation-lines.material-additive.11',
          'design-recycling.recommendation-lines.material-additive.13',
          'design-recycling.recommendation-lines.material-additive.14'
        ],
        "design-recycling.recommend-category.material-colour": [
          'design-recycling.recommendation-lines.material-colour.1',
          'design-recycling.recommendation-lines.material-colour.2',
          'design-recycling.recommendation-lines.material-colour.4'
        ],
        "design-recycling.recommend-category.inks-packaging": [
          'design-recycling.recommendation-lines.inks-packaging.1',
          'design-recycling.recommendation-lines.inks-packaging.2',
          'design-recycling.recommendation-lines.inks-packaging.3',
          'design-recycling.recommendation-lines.inks-packaging.4',
          'design-recycling.recommendation-lines.inks-packaging.5'
        ],
        "design-recycling.recommend-category.emptying-capability": [
        ],
        "design-recycling.recommend-category.sealing-parts": [
          'design-recycling.recommendation-lines.sealing-parts.1'
        ],
        "design-recycling.recommend-category.consumer-measure": [
          'design-recycling.recommendation-lines.consumer-measure.1',
          'design-recycling.recommendation-lines.consumer-measure.2',
          'design-recycling.recommendation-lines.consumer-measure.3'
        ]
      };
    else if (
      wasteType.startsWith("Automotive") ||
      wasteType.startsWith("Electronics") ||
      wasteType.startsWith("Furniture")
    )
      return {
        "design-recycling.recommend-category.material-additive": [
          'design-recycling.recommendation-lines.material-additive.1',
          'design-recycling.recommendation-lines.material-additive.2',
          'design-recycling.recommendation-lines.material-additive.3',
          'design-recycling.recommendation-lines.material-additive.4',
          'design-recycling.recommendation-lines.material-additive.5',
          'design-recycling.recommendation-lines.material-additive.7',
          'design-recycling.recommendation-lines.material-additive.8',
          'design-recycling.recommendation-lines.material-additive.9',
          'design-recycling.recommendation-lines.material-additive.10',
          'design-recycling.recommendation-lines.material-additive.11',
          'design-recycling.recommendation-lines.material-additive.13',
          'design-recycling.recommendation-lines.material-additive.14'
        ],
        "design-recycling.recommend-category.material-colour": [
          'design-recycling.recommendation-lines.material-colour.1',
          'design-recycling.recommendation-lines.material-colour.2',
          'design-recycling.recommendation-lines.material-colour.4'
        ],
        "design-recycling.recommend-category.inks-packaging": [
          'design-recycling.recommendation-lines.inks-packaging.1',
          'design-recycling.recommendation-lines.inks-packaging.2',
          'design-recycling.recommendation-lines.inks-packaging.3',
          'design-recycling.recommendation-lines.inks-packaging.4',
          'design-recycling.recommendation-lines.inks-packaging.5'
        ],
        "design-recycling.recommend-category.emptying-capability": [
        ],
        "design-recycling.recommend-category.sealing-parts": [
          'design-recycling.recommendation-lines.sealing-parts.1'
        ],
        "design-recycling.recommend-category.consumer-measure": [
          'design-recycling.recommendation-lines.consumer-measure.1',
          'design-recycling.recommendation-lines.consumer-measure.2',
          'design-recycling.recommendation-lines.consumer-measure.3'
        ]
      };
    else if (
      wasteType.startsWith("Agriculture") ||
      wasteType.startsWith("Household appliances")
    )
      return {
        "design-recycling.recommend-category.material-additive": [
          'design-recycling.recommendation-lines.material-additive.1',
          'design-recycling.recommendation-lines.material-additive.2',
          'design-recycling.recommendation-lines.material-additive.3',
          'design-recycling.recommendation-lines.material-additive.4',
          'design-recycling.recommendation-lines.material-additive.5',
          'design-recycling.recommendation-lines.material-additive.6',
          'design-recycling.recommendation-lines.material-additive.7',
          'design-recycling.recommendation-lines.material-additive.8',
          'design-recycling.recommendation-lines.material-additive.9',
          'design-recycling.recommendation-lines.material-additive.10',
          'design-recycling.recommendation-lines.material-additive.11',
          'design-recycling.recommendation-lines.material-additive.13',
          'design-recycling.recommendation-lines.material-additive.14'
        ],
        "design-recycling.recommend-category.material-colour": [
          'design-recycling.recommendation-lines.material-colour.1',
          'design-recycling.recommendation-lines.material-colour.2',
          'design-recycling.recommendation-lines.material-colour.4'
        ],
        "design-recycling.recommend-category.inks-packaging": [
          'design-recycling.recommendation-lines.inks-packaging.1',
          'design-recycling.recommendation-lines.inks-packaging.2',
          'design-recycling.recommendation-lines.inks-packaging.3',
          'design-recycling.recommendation-lines.inks-packaging.4',
          'design-recycling.recommendation-lines.inks-packaging.5'
        ],
        "design-recycling.recommend-category.emptying-capability": [
        ],
        "design-recycling.recommend-category.sealing-parts": [
          'design-recycling.recommendation-lines.sealing-parts.1'
        ],
        "design-recycling.recommend-category.consumer-measure": [
          'design-recycling.recommendation-lines.consumer-measure.1',
          'design-recycling.recommendation-lines.consumer-measure.2',
          'design-recycling.recommendation-lines.consumer-measure.3'
        ]
      };
    else if (wasteType.startsWith("Construction"))
      return {
        "design-recycling.recommend-category.material-additive": [
          'design-recycling.recommendation-lines.material-additive.1',
          'design-recycling.recommendation-lines.material-additive.2',
          'design-recycling.recommendation-lines.material-additive.3',
          'design-recycling.recommendation-lines.material-additive.4',
          'design-recycling.recommendation-lines.material-additive.5',
          'design-recycling.recommendation-lines.material-additive.7',
          'design-recycling.recommendation-lines.material-additive.8',
          'design-recycling.recommendation-lines.material-additive.9',
          'design-recycling.recommendation-lines.material-additive.10',
          'design-recycling.recommendation-lines.material-additive.11',
          'design-recycling.recommendation-lines.material-additive.12',
          'design-recycling.recommendation-lines.material-additive.13',
          'design-recycling.recommendation-lines.material-additive.14'
        ],
        "design-recycling.recommend-category.material-colour": [
          'design-recycling.recommendation-lines.material-colour.1',
          'design-recycling.recommendation-lines.material-colour.2',
          'design-recycling.recommendation-lines.material-colour.4'
        ],
        "design-recycling.recommend-category.inks-packaging": [
          'design-recycling.recommendation-lines.inks-packaging.1',
          'design-recycling.recommendation-lines.inks-packaging.2',
          'design-recycling.recommendation-lines.inks-packaging.3',
          'design-recycling.recommendation-lines.inks-packaging.4',
          'design-recycling.recommendation-lines.inks-packaging.5'
        ],
        "design-recycling.recommend-category.emptying-capability": [
        ],
        "design-recycling.recommend-category.sealing-parts": [
          'design-recycling.recommendation-lines.sealing-parts.1'
        ],
        "design-recycling.recommend-category.consumer-measure": [
          'design-recycling.recommendation-lines.consumer-measure.1',
          'design-recycling.recommendation-lines.consumer-measure.2',
          'design-recycling.recommendation-lines.consumer-measure.3'
        ]
      };
    else // last waste type: Medicine
      return {
        "design-recycling.recommend-category.material-additive": [
          'design-recycling.recommendation-lines.material-additive.1',
          'design-recycling.recommendation-lines.material-additive.2',
          'design-recycling.recommendation-lines.material-additive.3',
          'design-recycling.recommendation-lines.material-additive.4',
          'design-recycling.recommendation-lines.material-additive.5',
          'design-recycling.recommendation-lines.material-additive.6',
          'design-recycling.recommendation-lines.material-additive.8',
          'design-recycling.recommendation-lines.material-additive.9',
          'design-recycling.recommendation-lines.material-additive.10',
          'design-recycling.recommendation-lines.material-additive.11',
          'design-recycling.recommendation-lines.material-additive.12',
          'design-recycling.recommendation-lines.material-additive.13',
          'design-recycling.recommendation-lines.material-additive.14'
        ],
        "design-recycling.recommend-category.material-colour": [
          'design-recycling.recommendation-lines.material-colour.1',
          'design-recycling.recommendation-lines.material-colour.2',
          'design-recycling.recommendation-lines.material-colour.4'
        ],
        "design-recycling.recommend-category.inks-packaging": [
          'design-recycling.recommendation-lines.inks-packaging.1',
          'design-recycling.recommendation-lines.inks-packaging.2',
          'design-recycling.recommendation-lines.inks-packaging.3',
          'design-recycling.recommendation-lines.inks-packaging.4',
          'design-recycling.recommendation-lines.inks-packaging.5'
        ],
        "design-recycling.recommend-category.emptying-capability": [
        ],
        "design-recycling.recommend-category.sealing-parts": [
          'design-recycling.recommendation-lines.sealing-parts.1'
        ],
        "design-recycling.recommend-category.consumer-measure": [
          'design-recycling.recommendation-lines.consumer-measure.1',
          'design-recycling.recommendation-lines.consumer-measure.2',
          'design-recycling.recommendation-lines.consumer-measure.3'
        ]
      };
  };

  return { recommendations, wasteTypes, recommendationsByWasteType };
};

