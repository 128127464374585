import { useState } from "react";
import { ComparisonItem } from "../model/results";
import { ComparisonChart } from "./ComparisonChart";
import { EconomicAssessmentChart } from "./EconomicAssessmentChart";
import { ImpactDistributionChart } from "./ImpactDistributionChart";
import domToImage from "dom-to-image";
import ClipLoader from "react-spinners/ClipLoader";
import { EconParamInfluenceChart } from "./EconParamInfluenceChart";
import { useTranslation } from "react-i18next";
import c from "../utils/constants";
import stakeholderData from "../service/StakeholderData";
import cyclopsLogo from '../images/cyclops-logo.png';
import { ImpactDistributionData, Stakeholder } from "../model/model";
import dashboardDataManager from "../service/DashboardDataManager";
import { PlasticFields } from "../model/usePlasticFields";
import { t } from "i18next";

export const Dashboard = () => {
  const { t } = useTranslation();
  const SURVEY_LINK = process.env.REACT_APP_SURVEY_LINK;
  const stakeholderTransKey = stakeholderData.stakeholderTransKey;

  const dashboardData = dashboardDataManager
    .getDataByStakeholder(stakeholderData.stakeholder as Stakeholder);

  const {
    sampleData,
    plasticFields,
    inPlotData,
    plotLayout,
    chosenParameter
  } = dashboardData.econAssessChartData;

  const {
    chartData,
    chartComparisonSubjects,
    impactDistributionData
  } = dashboardData.ecolAssessChartData;

  let contributionImpactData: number[] | undefined = undefined;
  if (impactDistributionData)
    contributionImpactData = impactDistributionData["Climate change"];

  const { wasteMatrixImage } = dashboardData.wasteMatrixData;

  const [loading, setLoading] = useState(false);

  const filterDownload = (node: any) => {
    return node.className !== "spinner";
  };

  const downloadDashboard = () => {
    setLoading(true);
    const node = document.getElementById('dashboard-container');
    domToImage.toJpeg(node as HTMLElement, {
      filter: filterDownload,
      bgcolor: "white"
    }).then(imageUrl => {
      setLoading(false);
      const downloadLink = document.createElement('a');
      downloadLink.download = 'dashboard-results.jpg';
      downloadLink.href = imageUrl;
      downloadLink.click();
    });
  };

  const isContentAvailable = () => {
    return wasteMatrixImage || sampleData || inPlotData ||
      (chartData && chartData.length > 0);
  };

  return (
    <div className='section body-padding' id='dashboard-container'>
      <h3 className="title is-1 has-text-primary">
        <img src={cyclopsLogo} />&nbsp;
        {t('dashboard.title', { stakeholder: t(stakeholderTransKey) })}
      </h3>
      <div id='dashboard-container-content'>
        {!isContentAvailable() &&
          <article id="no-content" className="message is-info">
            <div className="message-header">
              <p>Info</p>
            </div>
            <div className="message-body">
              {t('dashboard.no-content')}
            </div>
          </article>
        }
        <div className="columns">
          <div className="column is-half">
            {false &&
              <div className="block">
                <div id="tips-dashboard" className="card">
                  <header className="card-header">
                    <p className="card-header-title">
                      {t('design-recycling.title')}
                    </p>
                  </header>
                  <div className="card-content">
                    <div className="content">
                    </div>
                  </div>
                </div>
              </div>
            }
            {wasteMatrixImage &&
              <div className="block">
                <div id="waste-matrix-dashboard" className="card">
                  <header className="card-header">
                    <p className="card-header-title">
                      {t('waste-matrix.title')}
                    </p>
                  </header>
                  <div className="card-content">
                    <div className="content">
                      <img src={wasteMatrixImage}></img>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="block">
              <button className="button download is-responsive is-info"
                disabled={!isContentAvailable()}
                onClick={downloadDashboard}>
                {t('dashboard.download')}
              </button>
              <a href={SURVEY_LINK} target="_blank">
                <button className="button survey is-responsive is-info">
                  {t('dashboard.survey')}
                </button>
              </a>
            </div>
            {
              loading
                ? <div className="spinner">
                  <ClipLoader color={c.CYCLOPS_BLUE}
                    loading={loading}
                    cssOverride={{
                      display: "block",
                      margin: "0 auto"
                    }} size={50} />
                </div>
                : <></>
            }
          </div>

          <div className="column is-half">
            {(sampleData || inPlotData) &&
              <div className="block">
                <div className="card">
                  <header className="card-header">
                    <p className="card-header-title">
                      {t('econ-assess.title')}
                    </p>
                  </header>
                  <div className="card-content">
                    <div className="content">
                      <EconomicAssessmentChart
                        sampleData={sampleData}
                      />
                      <PlasticFieldsLegend
                        plasticFields={plasticFields}
                      />
                    </div>
                    {chosenParameter &&
                      <label className="label">
                        {t(
                          'econ-assess.param-influence-price2',
                          { chosenParameter }
                        )}
                      </label>
                    }
                    <EconParamInfluenceChart
                      inPlotData={inPlotData}
                      plotLayout={plotLayout}
                    />
                  </div>
                </div>
              </div>
            }
            {chartData && chartData.length > 0 &&
              <div className="block">
                <div id="ecol-assess-dashboard" className="card">
                  <header className="card-header">
                    <p className="card-header-title">
                      {t('ecol-assess.title')}
                    </p>
                  </header>
                  <div className="card-content">
                    <div className="content">
                      <div className="columns">
                        <div className="column is-7">
                          <div id="impact-assess-chart">
                            <ComparisonChart items={chartData as ComparisonItem[]}
                              comparisonSubjects={chartComparisonSubjects as string[]}
                            />
                          </div>
                        </div>

                        <div className="column is-5">
                          {contributionImpactData &&
                            <div id="distribution-chart-container">
                              <ImpactDistributionChart
                                labels={Object.keys(impactDistributionData as ImpactDistributionData)}
                                input={contributionImpactData} />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div >
  );
};

const PlasticFieldsLegend = ({ plasticFields }: {
  plasticFields: PlasticFields | undefined
}) => {
  return (
    <>
      {plasticFields &&
        <div className="is-size-6">
          <h6>{t('econ-assess.legend')}</h6>
          <div className="columns">
            <div className="column">
              <div>{t('econ-assess.sales-value')}: {plasticFields.salesValue}</div>
              <div>{t('econ-assess.material-type')}: {plasticFields.materialType}</div>
              <div>{t('econ-assess.material-amount')}: {plasticFields.materialAmount}</div>
              <div>{t('econ-assess.din-spec')}: {plasticFields.dinSpec}</div>
            </div>
            <div className="column">
              <div>{t('econ-assess.offer-frequency')}: {plasticFields.recurring}</div>
              {plasticFields.recurring &&
                plasticFields.recurring === c.RECURRING &&
                <div>{t('econ-assess.frequency')}: {plasticFields.frequency}</div>
              }
              <div>{t('econ-assess.colour')}: {plasticFields.colour}</div>
              <div>{t('econ-assess.source')}: {plasticFields.residueFrom === 1 ?
                t('source-options.post-industrial') : t('source-options.post-consumer')}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
