import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { useTranslation } from 'react-i18next';

export const ImpactDistributionChart = ({
  labels,
  input
} : {
  labels: string[],
  input: number[]
}) => {
  const { t } = useTranslation();

  Chart.register(
    ArcElement,
    Tooltip,
    Legend
  );

  const data: ChartData<"pie"> = {
    labels: labels,
    datasets: [
      {
        data: input,
        backgroundColor: [
          'rgba(238, 255, 204, 1)',
          'rgba(213, 255, 128, 1)',
          'rgba(40, 199, 0, 1)',
          'rgba(35, 168, 2, 1)',
          'rgba(29, 135, 3, 1)',
          'rgba(21, 105, 1, 1)',
          'rgba(15, 77, 0, 1)',
          'rgba(9, 43, 0, 1)',
        ],
        borderColor: 'white',
        borderWidth: 2
      },
    ],
  };

  const options: ChartOptions<"pie"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
        align: "start",
        maxHeight: 150,
        maxWidth: 400
      },
      title: {
        display: true,
        text: t('ecol-assess.impact-distribution') as string
      },
      tooltip: {
        callbacks: {
          label: (context: { label: any; parsed: number; }) => {
            return `${context.label}: ${context.parsed.toExponential(3)}`;
          }
        }
      }
    },
    layout: {
      padding: 0
    }
  };

  return (
    <Pie data={data} options={options} />
  );
};
