import { useTranslation } from "react-i18next";
import { OptionItem } from "./model";
import constants from "../utils/constants";

export const useDinspecOptions = () => {
  const { t } = useTranslation();
  const { NO_INPUT, LEVEL_1, LEVEL_2, LEVEL_3, LEVEL_4 } = constants;

  const dinSpecOptions: OptionItem[] = [
    { text: t('dinspec-options.level1'), value: LEVEL_1 },
    { text: t('dinspec-options.level2'), value: LEVEL_2 },
    { text: t('dinspec-options.level3'), value: LEVEL_3 },
    { text: t('dinspec-options.level4'), value: LEVEL_4 },
    { text: t('dinspec-options.no-input'), value: NO_INPUT }
  ];

  return dinSpecOptions;
};

