import { useTranslation } from "react-i18next";
import { Ref } from "./model";

export const useReprocessingOptions = () => {
  const { t } = useTranslation();

  const context: Ref = {
    "@id": "877fa4dd-8523-45ff-be19-ddb2cf989711",
    "@type": "Process",
    "name": "Manufacturing Steps"
  };
  const defs = [
    {
      label: t('reprocessing-options.blow-moulding'),
      parameter: "blow_moulding",
      translationKey: 'reprocessing-options.blow-moulding'
    },
    {
      label: t('reprocessing-options.calendar-rigid-sheet'),
      parameter: "calendering_rigid_sheets",
      translationKey: 'reprocessing-options.calendar-rigid-sheet'
    },
    {
      label: t('reprocessing-options.coextrusion'),
      parameter: "coextrusion",
      translationKey: 'reprocessing-options.coextrusion'
    },
    {
      label: t('reprocessing-options.extrusion-sheet-inline'),
      parameter: "extrusion_sheets_inline",
      translationKey: 'reprocessing-options.extrusion-sheet-inline'
    },
    {
      label: t('reprocessing-options.extrusion-film'),
      parameter: "extrusion_film",
      translationKey: 'reprocessing-options.extrusion-film'
    },
    {
      label: t('reprocessing-options.extrusion-pipe'),
      parameter: "extrusion_pipes",
      translationKey: 'reprocessing-options.extrusion-pipe'
    },
    {
      label: t('reprocessing-options.foam'),
      parameter: "foaming",
      translationKey: 'reprocessing-options.foam'
    },
    {
      label: t('reprocessing-options.injection-moulding'),
      parameter: "injection_moulding",
      translationKey: 'reprocessing-options.injection-moulding'
    },
    {
      label: t('reprocessing-options.thermoforming-sheet'),
      parameter: "thermoforming_sheets",
      translationKey: 'reprocessing-options.thermoforming-sheet'
    },
    {
      label: t('reprocessing-options.thermoforming-calendar'),
      parameter: "thermoforming_calendering",
      translationKey: 'reprocessing-options.thermoforming-calendar'
    }
  ];
  return defs.map(def => ({ ...def, context }));
};
