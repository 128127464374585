import { Stakeholder } from "../model/model";

class StakeholderData {
  stakeholder: Stakeholder | undefined;
  stakeholderTransKey: string;

  constructor() {
    this.stakeholderTransKey = "";
  }

  setStakeholder(stakeholder: Stakeholder, stakeholderTransKey: string) {
    this.stakeholder = stakeholder;
    this.stakeholderTransKey = stakeholderTransKey;
  }
}

export default new StakeholderData();
