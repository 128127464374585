import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Recycler = () => {
  const { t } = useTranslation();

  return (
    <div className='body-wrapper body-padding' id='recycler-container'>
      <h3 className='title is-1 has-text-primary'>
        {t('recycler.title')}
      </h3>
      <div className='flex-break' />
      <div className='box'>
        <div className='content'>
          <p>{t('recycler.info')}</p>
        </div>
      </div>
      <div className='flex-break' />
      <div className='nav-button-wrapper'>
        <Link to='/buy-plastic-waste'>
          <button className='button is-medium nav-button
            is-responsive is-info'>{t('recycler.buy-plastic-waste')}</button>
        </Link>
      </div>
      <div className='flex-break' />
      <div className='nav-button-wrapper'>
        <Link to='/sell-recyclates'>
          <button className='button is-medium nav-button
            is-responsive is-info'>{t('recycler.sell-recyclate')}</button>
        </Link>
      </div>
    </div>
  );
};
