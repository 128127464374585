/**
 * A reference to an openLCA entity.
 */
export interface Ref {
  "@id": string;
  "@type"?: string;
  "name"?: string;
  "refUnit"?: string;
  "category"?: string;
}

export interface ParameterRedef {
  name: string;
  value: number;
  context?: Ref;
}

export interface ImpactResult {
  impactCategory: Ref;
  amount: number;
}

export interface OpenLCAProcess {
  "@id": string;
  "@type": string;
  name: string;
  category: string;
  processType: string;
  flowType: string;
}

export interface ProcessCategory {
  title: string;
  category: string;
  subProcesses: OpenLCAProcess[];
  subCategories: ProcessCategory[];
}

export interface OptionItem {
  text: string;
  value: string;
}

export enum Stakeholder {
  WasteOwner = "Waste Owner",
  Recycler = "Recycler",
  Converter = "Converter"
}

export interface ImpactDistributionData {
  [key: string]: number[];
}
