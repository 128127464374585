import { Layout } from "plotly.js";
import Plot from "react-plotly.js";

export const EconParamInfluenceChart = ({
  inPlotData,
  plotLayout
} : {
  inPlotData: Plotly.Data[] | undefined;
  plotLayout: Partial<Layout> | undefined;
}) => {
    return (
      <Plot
          data={inPlotData as Plotly.Data[]}
          layout={plotLayout as Partial<Layout>}
        />
    );
};
