const constants = {
  // default select value
  NO_INPUT: "No input",

  // recurring offers
  ONCE: "One-off",
  RECURRING: "Recurring",

  // frequency values
  WEEKLY: "Weekly",
  BI_WEEKLY: "Bi-weekly",
  MONTHLY: "Monthly",
  QUARTERLY: "Quarterly",
  YEARLY: "Yearly",

  // economic assessment parameters
  DIN_SPEC: "DIN spec 91446",
  COLOR: "Color",
  FREQUENCY: "Frequency",
  AMOUNT_IN_TON: "Amount in ton",

  // colours
  GREY: "Grey",
  WHITE: "White",
  TRANSPARENT: "Transparent",
  MIXTURE: "Mixture",

  // colour codes
  CYCLOPS_BLUE: "#0870ba",
  CYCLOPS_LIGHT_BLUE: "#29a9e0",
  DARK_GREEN: "#76b629",
  GREY_BORDER: "rgb(209, 209, 209)",
  COLOR_SCALE: "YlGnBu",
  OPACITY: 0.5,

  // DIN spec values
  LEVEL_1: "Level 1",
  LEVEL_2: "Level 2",
  LEVEL_3: "Level 3",
  LEVEL_4: "Level 4",

  // 0 and 1 in strings
  ZERO: "0",
  ONE: "1",

  // language codes
  EN: "en",
  DE: "de",

  // recycling steps
  WASH: "Wash",
  OPEN_DISSOLVE: "Open & Dissolve",
  AGGLOMERATE_COMPRESS: "Agglomerate & compress",
  SORT_SEPARATE: "Sort & separate",
  SHRED: "Shred",
  PELLETIZERS: "Pelletizers & combined systems",
  DRY: "Dry",

  PACKAGING: "Packaging"
};

export default constants;
