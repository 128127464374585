import { useTranslation } from "react-i18next";
import { OptionItem } from "./model";
import constants from "../utils/constants";

export const useSourceOptions = () => {
  const { t } = useTranslation();
  const { NO_INPUT, ZERO, ONE } = constants;

  const sourceOptions: OptionItem[] = [
    { text: t('source-options.post-consumer'), value: ZERO },
    { text: t('source-options.post-industrial'), value: ONE },
    { text: t('source-options.no-input'), value: NO_INPUT },
  ];

  return sourceOptions;
};

