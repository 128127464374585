import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import stakeholderData from '../service/StakeholderData';
import { Stakeholder } from '../model/model';

export const ChooseStakeholder = () => {
  const { t } = useTranslation();

  return (
    <div className='body-wrapper body-padding' id='choose-stakeholder'>
      <h3 className='title is-1 has-text-primary'>
        {t('stakeholder.title')}
      </h3>
      <div className='flex-break' />
      <div className='columns' id='stakeholder-buttons'>
        <div className='column'>
          <Link
            to='/waste-owner-tools'
            onClick={() =>
              stakeholderData.setStakeholder(
                Stakeholder.WasteOwner,
                'stakeholder.waste-owner.title'
              )
            }
          >
            <button className='button is-medium
                  is-responsive is-info nav-button'
            >
              {t('stakeholder.waste-owner.title')}
            </button>
          </Link>
          <div className='box'>
            {t('stakeholder.waste-owner.info')}
          </div>
        </div>
        <div className='column'>
          <Link
            to='/recycler'
            onClick={() =>
              stakeholderData.setStakeholder(
                Stakeholder.Recycler,
                'stakeholder.recycler.title'
              )
            }
          >
            <button className='button is-medium
                  is-responsive is-info nav-button'
            >
              {t('stakeholder.recycler.title')}
            </button>
          </Link>
          <div className='box'>
            {t('stakeholder.recycler.info')}
          </div>
        </div>
        <div className='column'>
          <Link
            to='/converter-tools'
            onClick={() =>
              stakeholderData.setStakeholder(
                Stakeholder.Converter,
                'stakeholder.converter.title'
              )
            }
          >
            <button className='button is-medium
                  is-responsive is-info nav-button'
            >
              {t('stakeholder.converter.title')}
            </button>
          </Link>
          <div className='box'>
            {t('stakeholder.converter.info')}
          </div>
        </div>
      </div>
      <div className='flex-break' />
    </div>
  );
};
