import { Data, Layout } from "plotly.js";
import { EcoAssessDataItem } from "../model/economicAssessment";
import { BUYER_MATERIALS } from "../model/materials";
import { TFunction } from "i18next";
import c from "./constants";

const randomNormal = require('random-normal');

export const maxNumberInList = (list: number[]) => {
  const copy = [...list];
  copy.sort((a, b) => a - b);
  return copy.pop() as number;
};

export const minNumberInList = (list: number[]) => {
  const copy = [...list];
  copy.sort((a, b) => a - b);
  return copy.shift() as number;
};

export const getMuAndSigmaForMaterial = (
  materialType: string,
  materialTypes: string[]
) => {
  let initialMu = 700;
  const muSigmaList = materialTypes.map((material, index) => {
    if ((index + 1) % 2 === 0) {
      initialMu += 100;
    }
    return [initialMu, initialMu / 10];
  });
  return muSigmaList[materialTypes.indexOf(materialType)];
};

const dinSpecFactorMapping = {
  [c.LEVEL_1]: 0.5,
  [c.LEVEL_2]: 0.75,
  [c.LEVEL_3]: 1,
  [c.LEVEL_4]: 1.5
};

const recurringOfferFactorMapping = {
  [c.ONCE]: 0.9,
  [c.RECURRING]: 1
};

const recyclateSourceFactorMapping = {
  [c.ZERO]: 0.75,
  [c.ONE]: 1
};

export const getMaterialTypes = () => {
  const types: string[] = [];
  for (const material of BUYER_MATERIALS)
    for (const flow of material.flows)
      types.push(flow.label);
  return types;
};

export const generateItemArray = (
  array: number[] | string[],
  itemNumber: number
) => {
  const randomIndexArray = [];
  for (let i = 0; i < itemNumber; i++) {
    randomIndexArray.push(
      Math.floor(Math.random() * array.length)
    );
  }
  return randomIndexArray.map((index: number) => array[index]);
};

export const getPrice = (
  recurringOffer: string,
  dinSpec: string,
  materialType: string,
  materialTypes: string[],
  recyclateSource: number,
  continuousVariable: number
) => {
  let [mu, sigma] = getMuAndSigmaForMaterial(materialType, materialTypes);

  const dinSpecFactor =
    dinSpecFactorMapping[
    dinSpec as keyof typeof dinSpecFactorMapping
    ];
  mu *= dinSpecFactor;
  sigma *= Math.sqrt(dinSpecFactor);

  const recurringOfferFactor =
    recurringOfferFactorMapping[
    recurringOffer as
    keyof typeof recurringOfferFactorMapping
    ];
  mu *= recurringOfferFactor;
  sigma *= Math.sqrt(recurringOfferFactor);

  const recyclateSourceFactor =
    recyclateSourceFactorMapping[
    recyclateSource.toString() as
    keyof typeof recyclateSourceFactorMapping
    ];
  mu *= recyclateSourceFactor;
  sigma *= Math.sqrt(recyclateSourceFactor);

  mu *= (1 - 0.05 * ((20 - continuousVariable) / 20));
  sigma *= Math.sqrt((1 - 0.05 * ((20 - continuousVariable) / 20)));
  return randomNormal({ mean: mu, dev: sigma });
};

export const getPriceList = (
  itemNumber: number,
  recurringOfferList: string[],
  dinSpecList: string[],
  materialTypeList: string[],
  materialTypes: string[],
  recyclateSourceList: number[],
  amountInTonList: number[]
) => {
  const priceList: number[] = [];
  for (let i = 0; i < itemNumber; i++) {
    const price = getPrice(
      recurringOfferList[i],
      dinSpecList[i],
      materialTypeList[i],
      materialTypes,
      recyclateSourceList[i],
      amountInTonList[i]
    );
    priceList.push(Math.round(price));
  }
  return priceList;
};

export const createHoverTextList = (
  priceListDisplay: number[],
  repetitionCount: number[],
  t:TFunction<"translation", undefined, "translation">
) => {
  const hoverTextList: string[] = [];
  priceListDisplay.forEach((price, index) => {
    const text =
      `${t('chart.price-euro')}: ${price} <br> ${t('chart.amount')}: ${repetitionCount[index]}`;
    hoverTextList.push(text);
  });
  return hoverTextList;
};

export const createPriceListDisplayAndRepetitionCount = (
  priceList: number[]
) => {
  const priceListDisplay: number[] = [];
  const repetitionCount: number[] = [];
  const priceRepetitionCount: { [key: string]: number } = {};

  for (const price of priceList) {
    priceRepetitionCount[price] = (priceRepetitionCount[price] || 0) + 1;
  }

  for (const pair of Object.entries(priceRepetitionCount)) {
    priceListDisplay.push(parseInt(pair[0]));
    repetitionCount.push(pair[1]);
  }
  return [priceListDisplay, repetitionCount];
}

export const convertDataListsToObjects = (
  recurringOfferList: string[],
  colorList: string[],
  dinSpecList: string[],
  materialTypeList: string[],
  recyclateSourceList: number[],
  frequencyList: string[],
  amountInTonList: number[],
  priceList: number[],
  ITEM_NUMBER: number
) => {
  const plotData: EcoAssessDataItem[] = [];
  for (let i = 0; i < ITEM_NUMBER; i++) {
    plotData.push({
      recurringOffer: recurringOfferList[i],
      color: colorList[i],
      dinSpec: dinSpecList[i],
      materialType: materialTypeList[i],
      recyclateSource: recyclateSourceList[i],
      frequency: frequencyList[i],
      amountInTon: amountInTonList[i],
      price: priceList[i]
    });
  }
  return plotData;
};

export const preparePlotLayout = (
  setPlotLayout: React.Dispatch<
    React.SetStateAction<Partial<Layout> | undefined>
  >,
  t: TFunction<"translation", undefined, "translation">,
  xAxisText?: string,
  yAxisText?: string,
) => {
  const layout: Partial<Layout> = {
    width: 700,
    height: 270,
    title: t('chart.parameter-influence-price') as string,
    showlegend: false,
    margin: {
      t: 30,
      b: 40,
      l: 90,
      r: 0
    }
  };
  if (xAxisText) {
    layout.xaxis = {
      title: {
        text: xAxisText
      }
    };
  }
  if (yAxisText) {
    layout.yaxis = {
      title: {
        text: yAxisText
      }
    };
  }
  setPlotLayout(layout);
};

export const prepareInPlotFluctuationData = (
  amountRanges: number[],
  filteredSampleData: EcoAssessDataItem[][],
  t: TFunction<"translation", undefined, "translation">,
  setInPlotData?: React.Dispatch<React.SetStateAction<Data[] | undefined>>
) => {
  const mediumPriceList: number[] = [];
  const priceFluctuationList: number[] = [];
  const hoverTextList: string[] = [];

  for (const list of filteredSampleData) {
    if (!list || list.length === 0)
      return;
    const priceList = list.map(item => item.price);
    const min = minNumberInList(priceList);
    const max = maxNumberInList(priceList);
    const mediumPrice = (min + max) / 2;
    const priceFluctuation = (max - min) / 2;
    const hoverText = `${t('chart.amount-ton')}: ${list[0].amountInTon}<br>`
      + `${t('chart.price-euro')}: ${mediumPrice} ± ${priceFluctuation}`;
    mediumPriceList.push(mediumPrice);
    priceFluctuationList.push(priceFluctuation);
    hoverTextList.push(hoverText);
  }

  const data: Plotly.Data[] = [{
    x: amountRanges,
    y: mediumPriceList,
    error_y: {
      type: 'data',
      array: priceFluctuationList,
      visible: true
    },
    type: 'bar',
    hoverinfo: 'text',
    hovertext: hoverTextList,
  }];

  if (setInPlotData)
    setInPlotData(data);
  else
    return data;
};

export const generatePriceFluctuationPlotData = (
  sampleData: EcoAssessDataItem[],
  t: TFunction<"translation", undefined, "translation">,
  setPlotLayout?: React.Dispatch<
    React.SetStateAction<Partial<Layout> | undefined>
  >,
  setInPlotData?: React.Dispatch<React.SetStateAction<Data[] | undefined>>,
  returnData = false
) => {
  if (!sampleData || sampleData.length === 0)
    return;
  const amountList = sampleData.map(item => item.amountInTon);
  const maxAmount = maxNumberInList(amountList);
  const interval = maxAmount / 19;
  let amountRanges: number[] = [];
  let filteredSampleData: EcoAssessDataItem[][] = [];
  amountRanges.push(1);
  for (let i = 0; i < 18; i++) {
    const lastRange = amountRanges[amountRanges.length - 1];
    amountRanges.push(interval + lastRange);
  }
  amountRanges.push(maxAmount);
  amountRanges = amountRanges.map(range => Math.ceil(range));
  filteredSampleData = amountRanges.map(range =>
    sampleData.filter(item => item.amountInTon === range)
  );
  filteredSampleData.forEach((list, index) => {
    if (!list || list.length === 0)
      amountRanges.splice(index, 1);
  });
  if (!returnData && setInPlotData && setPlotLayout) {
    prepareInPlotFluctuationData(
      amountRanges,
      filteredSampleData,
      t,
      setInPlotData
    );
    preparePlotLayout(
      setPlotLayout,
      t,
      t('chart.amount-ton') as string,
      t('chart.price-euro') as string
    );
  }
  if (returnData) {
    return prepareInPlotFluctuationData(
      amountRanges,
      filteredSampleData,
      t
    );
  }
};

export const getMarkerSizeListByAmountRepetition = (
  repetitionCount: number[],
  MAX_MARKER_SIZE: number
) => {
  if (!repetitionCount || repetitionCount.length === 0)
    return;
  const maxCount = maxNumberInList(repetitionCount);
  return repetitionCount.map(
    count => Math.floor((count / maxCount) * MAX_MARKER_SIZE)
  );
};
