import EcolAssessChartData from "./EcolAssessChartData";
import EconAssessChartData from "./EconAssessChartData";
import WasteMatrixData from "./WasteMatrixData";

class DashboardData {
  econAssessChartData: EconAssessChartData;
  ecolAssessChartData: EcolAssessChartData;
  wasteMatrixData: WasteMatrixData;

  constructor() {
    this.econAssessChartData = new EconAssessChartData();
    this.ecolAssessChartData = new EcolAssessChartData();
    this.wasteMatrixData = new WasteMatrixData();
  }
}

export default DashboardData;
