import { useTranslation } from "react-i18next";

export const useTechFlowIdByRecycleProcName = () => {
  const { t } = useTranslation();

  const mapping: { [key: string]: string } = {
    [t('ecol-assess.recycling-step.wash')]:
      "a3c6c9da-be99-4b98-ae6d-76209b9fd654::d8466beb-4418-4741-8ef7-e26fe26e1426",
    [t('ecol-assess.recycling-step.agglomerate-compress')]:
      "24911af3-116d-496a-a682-392ac46bc978::beac706d-41af-4ba3-8a74-419206007a1e",
    [t('ecol-assess.recycling-step.dry')]:
      "ee4eb36e-17dd-4937-bffc-3242e491644d::9b42a8a8-bfa2-4756-b3bf-cbe5c9ceb5e6",
    [t('ecol-assess.recycling-step.open-dissolve')]:
      "7beca0c4-4415-458c-a3db-d9d06be18f11::75f9471f-6d7f-4f85-ba40-146f2af1481a",
    [t('ecol-assess.recycling-step.pelletizer')]:
      "be86ffef-6623-49ac-8ed0-1e7d7a564560::40be6160-c03e-4d56-9156-2eea5a6b2852",
    [t('ecol-assess.recycling-step.shred')]:
      "ebf71da2-db54-483c-b064-e9b3fd28bf74::0d322658-f4ed-42e9-bd9b-c6cf14fd2575",
    [t('ecol-assess.recycling-step.sort-separate')]:
      "2a1d5be9-0a22-4b1d-8384-7f20262dce30::9a533c9f-3ca5-4c5a-9cb1-ef5917c6a1f7",
    [t('ecol-assess.transport.transport')]:
      "c5920e67-98ac-40b6-af41-258c93973ed5::02b4f54c-7c43-40d7-a9ff-63b8fb151d7a"
  };

  return mapping;
};
