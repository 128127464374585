import { Stakeholder } from "../model/model";
import DashboardData from "./DashboardData";

class DashboardDataManager {
  converterData: DashboardData;
  recyclerData: DashboardData;
  wasteOwnerData: DashboardData;

  constructor() {
    this.converterData = new DashboardData();
    this.recyclerData = new DashboardData();
    this.wasteOwnerData = new DashboardData();
  }

  getDataByStakeholder(stakeholder: Stakeholder) {
    switch(stakeholder) {
      case Stakeholder.WasteOwner:
        return this.wasteOwnerData;
      case Stakeholder.Recycler:
        return this.recyclerData;
      case Stakeholder.Converter:
        return this.converterData;
    }
  }
}

export default new DashboardDataManager();
